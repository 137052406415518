/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useLayoutEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";

import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import cn from "classnames";
import { format, isBefore, isSameDay } from "date-fns";

import AlertModal from "../../components/AlertModal";
import CustomModalFinalizaAtendimento from "../../components/CustomModalFinalizaAtendimento";
import CustomModalMotivoCancelamento from "../../components/CustomModalMotivoCancelamento";
import useApp from "../../hooks/useApp";
import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import "./styles.css";

export default function AgendaPage() {
  const alertModalRef = useRef();
  const modalMotivoCancelamentoRef = useRef();
  const customModalFinalizaAtendimentoRef = useRef();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [eventsOfSelectedDate, setEventsOfSelectedDate] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const { setLoading, setHeaderTitle, notificationRef } = useApp();
  const { loggedUser } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const [startCalendarDate, setStartCalendarDate] = useState();
  const [endCalendarDate, setEndCalendarDate] = useState();

  useLayoutEffect(() => {
    setHeaderTitle("Agenda");
  }, [setHeaderTitle]);

  function renderEventContent(eventInfo) {
    const { start, extendedProps } = eventInfo.event || {};
    const { type } = eventInfo.view;
    const event = events.find((e) => e.id === eventInfo.event.id);

    const eventColor =
      event?.especialidade?.cor ||
      event?.cor ||
      extendedProps?.color ||
      "#0082c8";

    if (type.includes("Month")) {
      return (
        <div
          className={cn("event-content-month", {
            "opacity-5": event?.preAgendamento,
          })}
          style={{ backgroundColor: eventColor }}
        >
          <i
            className={cn(
              "fas fa-exclamation-triangle exclamation-icon d-none",
              {
                "d-block":
                  !event?.preAgendamento &&
                  event?.start &&
                  event.type === "atendimento" &&
                  isBefore(new Date(event.start), new Date()) &&
                  event.status === "aberto",
              }
            )}
          />
          <i
            className={cn("fas fa-question-circle reagendamento-icon d-none", {
              "d-block": event?.preAgendamento,
            })}
          />
        </div>
      );
    }

    if (type.includes("Week")) {
      return (
        <div
          className={cn("event-content", {
            "opacity-5": event?.preAgendamento,
          })}
          style={{ backgroundColor: eventColor }}
        >
          {start && (
            <b className="event-time">{format(new Date(start), "HH'h'")}</b>
          )}
          <i className="event-title-ellipsis">{eventInfo.event.title}</i>
          <i
            className={cn(
              "fas fa-exclamation-triangle exclamation-icon d-none",
              {
                "d-block":
                  !event?.preAgendamento &&
                  event?.start &&
                  isBefore(new Date(event.start), new Date()) &&
                  event.status === "aberto",
              }
            )}
          />
          <i
            className={cn("fas fa-question-circle reagendamento-icon d-none", {
              "d-block": event?.preAgendamento,
            })}
          />
        </div>
      );
    }

    return (
      <div
        className={cn("event-content", {
          "opacity-5": event?.preAgendamento,
        })}
        style={{ backgroundColor: eventColor }}
      >
        {eventInfo.timeText && (
          <b className="event-time">{eventInfo.timeText}</b>
        )}
        <i className="event-title-ellipsis">{eventInfo.event.title}</i>
        <i
          className={cn("fas fa-exclamation-triangle exclamation-icon d-none", {
            "d-block":
              !event?.preAgendamento &&
              event?.start &&
              isBefore(new Date(event.start), new Date()) &&
              event.status === "aberto",
          })}
        />
        <i
          className={cn("fas fa-question-circle reagendamento-icon d-none", {
            "d-block": event?.preAgendamento,
          })}
        />
      </div>
    );
  }

  function handleSelection(info) {
    console.log("🚀 ~ handleSelection ~ info:", info);
    const { date } = info;
    const { type } = info.view;

    // Primeiro, remove a classe de todas as células
    document.querySelectorAll(".fc-day-selected").forEach((el) => {
      el.classList.remove("fc-day-selected");
    });

    // Formata a data para o formato YYYY-MM-DD para garantir compatibilidade
    const formattedDate = format(new Date(date), "yyyy-MM-dd");

    // Encontra a célula do dia e adiciona a classe
    const dayCell = document.querySelector(
      `[data-date="${formattedDate}"] .fc-daygrid-day-number`
    );
    if (dayCell) {
      dayCell.classList.add("fc-day-selected");
    }

    setSelectedDate(date);
    if (type.includes("Month")) {
      const _eventsOfSelectedDate = events
        .filter((e) => isSameDay(new Date(e.start), new Date(date)))
        .sort((a, b) => new Date(a.start) - new Date(b.start));
      setEventsOfSelectedDate(_eventsOfSelectedDate);

      if (_eventsOfSelectedDate?.length) {
        return;
      }
    }

    alertModalRef.current.show({
      title: "Lembrete ou Atendimento",
      message: "Deseja criar um lembrete ou atendimento?",
      confirmText: "Novo Atendimento",
      confirmColor: "secondary",
      cancelText: "Lembretes",
      cancelColor: "primary",
      onConfirm: () => {
        navigate(`/agendamento?data=${info.start || info.date}`);
      },
      onCancel: () => {
        navigate(`/lembrete?data=${info.start || info.date}`);
      },
      option1Text: undefined,
      onOption1: undefined,
      option2Text: undefined,
      onOption2: undefined,
    });
  }

  const loadAgendamentos = async (firstDayOfCalendar, lastDayOfCalendar) => {
    const { data: agendamentos } = await api.get(
      `/profissionalAgendamentosByBetweenDates?dataInicio=${firstDayOfCalendar}&dataTermino=${lastDayOfCalendar}`
    );
    // console.log("🚀 ~ loadAgendamentos ~ agendamentos:", agendamentos);
    return agendamentos;
  };

  const loadEventos = async (firstDayOfCalendar, lastDayOfCalendar) => {
    const { data: eventos } = await api.get(
      `/profissionalEventos?dataInicio=${firstDayOfCalendar}&dataTermino=${lastDayOfCalendar}`
    );
    // console.log("🚀 ~ loadEventos ~ eventos:", eventos);
    return eventos;
  };

  const onLoadEvents = useCallback(
    ({ startDate: _startDate, endDate: _endDate, updating = false }) => {
      const startDate = _startDate || startCalendarDate;
      const endDate = _endDate || endCalendarDate;

      if (startDate && endDate) {
        (async () => {
          try {
            setLoading(true);
            const firstDayOfCalendar = startDate;
            const lastDayOfCalendar = endDate;

            // console.log(firstDayOfCalendar, lastDayOfCalendar);
            if (firstDayOfCalendar && lastDayOfCalendar) {
              setLoading({
                loading: true,
                message: `${
                  updating ? "Atualizando" : "Carregando"
                } agendamentos...`,
              });
              const agendamentos = await loadAgendamentos(
                firstDayOfCalendar,
                lastDayOfCalendar
              );
              setLoading({
                loading: true,
                message: `${
                  updating ? "Atualizando" : "Carregando"
                } eventos...`,
              });
              const eventos = await loadEventos(
                firstDayOfCalendar,
                lastDayOfCalendar
              );

              const _events = [
                ...agendamentos.map((d) => ({
                  ...d,
                  id: d._id,
                  type: "atendimento",
                  title: `(${d.cliente?.nome})` || "(Agendamento)",
                  start: d.dataHoraInicio,
                  end: d.dataHoraTermino,
                })),
                ...eventos.map((d) => ({
                  ...d,
                  id: d._id,
                  type: "lembrete",
                  title: d.nome,
                  start: d.dataHoraInicio,
                  end: d.dataHoraTermino,
                })),
              ];
              setEvents(_events);
              // update events of selected date
              setEventsOfSelectedDate((prevStates) =>
                _events
                  .filter((e) => prevStates.find((s) => s.id === e.id))
                  .sort((a, b) => new Date(a.start) - new Date(b.start))
              );
              setLoading(false);
            }
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        })();
      }
    },
    [startCalendarDate, endCalendarDate]
  );

  const onCancelled = useCallback(
    (clickInfo, event) => {
      console.log("🚀 ~ onCancelled ~ data:", clickInfo, event?.id);

      /* clickInfo?.event?.remove();
      setEvents(
        events.filter(
          (e) =>
            e.id !== event?.id &&
            e.agendamentoId !== event?.id &&
            e.eventoId !== event?.id
        )
      );
      setEventsOfSelectedDate((prevSelectedEvents) =>
        prevSelectedEvents.filter(
          (e) =>
            e.id !== event?.id &&
            e.agendamentoId !== event?.id &&
            e.eventoId !== event?.id
        )
      ); */
      modalMotivoCancelamentoRef.current.close();
      onLoadEvents({ updating: true });
    },
    [events]
  );

  const onFinished = useCallback(
    (data) => {
      console.log("🚀 ~ onFinished ~ data:", data);
      customModalFinalizaAtendimentoRef.current.close();

      notificationRef.current.notify({
        message: "Atendimento finalizado com sucesso",
      });
      onLoadEvents({ updating: true });
    },
    [events]
  );

  const onCancelPreAgendamento = useCallback(({ event, onSuccess }) => {
    (async () => {
      try {
        setLoading(true);
        await api.delete(`/profissionalAgendamentos/${event.id}`);
        setLoading(false);
        onSuccess?.();
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  const updateEventToCancelled = async (id) => {
    try {
      setLoading(true);
      // /profissionalEventos/:id/status/:status
      const { data: updatedEvent } = await api.put(
        `/profissionalEventos/${id}/status/cancelado`
      );
      console.log(
        "🚀 ~ handleUpdateEventToCancelled ~ updatedEvent:",
        updatedEvent
      );
      notificationRef.current.notify({
        message: "Lembrete cancelado com sucesso",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      notificationRef.current.notify({
        message: "Erro ao cancelar lembrete",
      });
    }
  };

  function onEventClick(event, clickInfo) {
    if (event?.profissional !== loggedUser?.id) {
      alertModalRef.current.show({
        title: "Lembrete",
        message: `Lembrete ${event.nome} agendado para ${format(
          new Date(event.start),
          "dd/MM/yyyy HH:mm"
        )} até ${format(new Date(event.end), "dd/MM/yyyy HH:mm")}. ${
          event.observacoes ? `Observações: ${event.observacoes}` : ""
        }`,
        confirmText: "OK",
        confirmColor: "primary",
        onConfirm: () => {
          alertModalRef.current.close();
        },
        hideCancel: true,
      });
      return;
    }

    console.log("🚀 ~ handleEventClick ~ event:", event);
    if (event.type === "atendimento") {
      if (event?.preAgendamento) {
        alertModalRef.current.show({
          title: "Atendimento Pré-agendado",
          message: `O atendimento ${
            event.cliente?.nome
          } pré-agendado dia ${format(
            new Date(event.start),
            "dd/MM/yyyy HH:mm"
          )}.`,
          confirmText: "Editar Agendamento Original",
          confirmColor: "primary",
          hideCancel: true,
          onConfirm: () => {
            alertModalRef.current.close();
            navigate(`/agendamento/${event.agendamentoId}`);
          },
          option1Text: "Editar",
          onOption1: () => {
            alertModalRef.current.close();
            navigate(`/agendamento/${event.id}`);
          },
          option2Text: "Excluir",
          option2Color: "danger",
          onOption2: () => {
            alertModalRef.current.close();
            setTimeout(() => {
              alertModalRef.current.show({
                title: "Excluir Pré-agendamento",
                message: "Tem certeza que deseja excluir o pré-agendamento?",
                confirmText: "Sim",
                confirmColor: "danger",
                cancelText: "Não",
                cancelColor: "primary",
                onConfirm: () => {
                  alertModalRef.current.close();
                  onCancelPreAgendamento({
                    event,
                    onSuccess: () => {
                      onCancelled(clickInfo, event);
                    },
                  });
                },
                onCancel: () => {
                  alertModalRef.current.close();
                },
              });
            }, 500);
          },
        });
        return;
      }
      alertModalRef.current.show({
        title: "Atendimento",
        message: `O que deseja fazer com o atendimento para o paciente ${
          event.cliente?.nome
        }, agendado para ${format(new Date(event.start), "dd/MM/yyyy HH:mm")}?`,
        confirmText: "Editar",
        confirmColor: "primary",
        cancelText: "Excluir",
        cancelColor: "danger",
        option1Text: event.status === "aberto" ? "Finalizar" : undefined,
        option1Color: "success",
        onConfirm: () => {
          navigate(`/agendamento/${event.id}`);
        },
        onCancel: () => {
          alertModalRef.current.close();
          modalMotivoCancelamentoRef.current.show({
            _id: event.id,
            onSave: () => onCancelled(clickInfo, event),
          });
        },
        onOption1: () => {
          alertModalRef.current.close();
          customModalFinalizaAtendimentoRef.current.show({
            _id: event.id,
            cliente: event.cliente,
            especialidade: event.especialidade,
            dataHoraInicio: event.dataHoraInicio,
            dataHoraTermino: event.dataHoraTermino,
            onSave: () => onFinished(clickInfo, event),
          });
        },
      });
      return;
    }

    if (event?.preAgendamento) {
      alertModalRef.current.show({
        title: "Lembrete Pré-agendado",
        message: `O lembrete ${event.nome} pré-agendado dia ${format(
          new Date(event.start),
          "dd/MM/yyyy HH:mm"
        )}.`,
        confirmText: "Editar Lembrete Original",
        confirmColor: "primary",
        hideCancel: true,
        onConfirm: () => {
          alertModalRef.current.close();
          navigate(`/lembrete/${event.eventoId}`);
        },
        option1Text: undefined,
        onOption1: undefined,
        option2Text: undefined,
        onOption2: undefined,
      });
      return;
    }

    alertModalRef.current.show({
      title: "Lembrete",
      message: "O que deseja fazer com o lembrete?",
      confirmText: "Editar",
      confirmColor: "primary",
      cancelText: "Excluir",
      cancelColor: "danger",
      onConfirm: () => {
        navigate(`/lembrete/${event.id}`);
      },
      onCancel: () => {
        alertModalRef.current.close();
        setTimeout(() => {
          alertModalRef.current.show({
            title: "Excluir Lembrete",
            message: "Tem certeza que deseja excluir o lembrete?",
            confirmText: "Sim",
            confirmColor: "danger",
            cancelText: "Não",
            cancelColor: "primary",
            onConfirm: async () => {
              alertModalRef.current.close();
              await updateEventToCancelled(event.id);
              onCancelled(clickInfo, event);
            },
            onCancel: () => {
              alertModalRef.current.close();
            },
          });
        }, 500);
      },
    });
  }

  function handleEventClick(clickInfo) {
    console.log("🚀 ~ handleEventClick ~ clickInfo:", clickInfo.event.id);
    const event = events.find((e) => e.id === clickInfo.event.id);

    onEventClick(event, clickInfo);
  }

  function handleDatesSet(dateInfo) {
    setStartCalendarDate(dateInfo.start);
    setEndCalendarDate(dateInfo.end);
    onLoadEvents({ startDate: dateInfo.start, endDate: dateInfo.end });
    // Força a atualização das classes após a mudança de datas
    document.querySelectorAll(".fc-daygrid-day").forEach((el) => {
      const date = el.getAttribute("data-date");
      if (
        selectedDate &&
        date &&
        isSameDay(new Date(date), new Date(selectedDate))
      ) {
        el.classList.add("fc-day-selected");
      }
    });
  }

  function handleViewDidMount(viewInfo) {
    setCurrentView(viewInfo.view.type);
  }

  function renderEventStatus(event) {
    if (event.status === "aberto") {
      return <Badge color="info">Em Aberto</Badge>;
    }
    if (event.status === "concluido") {
      return <Badge color="success">Finalizado</Badge>;
    }
    if (event.status === "cancelado") {
      return <Badge color="danger">Cancelado</Badge>;
    }
    return null;
  }

  return (
    <div>
      <AlertModal ref={alertModalRef} />
      <CustomModalMotivoCancelamento ref={modalMotivoCancelamentoRef} />
      <CustomModalFinalizaAtendimento ref={customModalFinalizaAtendimentoRef} />
      <div className="agenda-container">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "title",
            center: "prev,next,today",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          locale={ptBrLocale}
          initialView="dayGridMonth"
          editable
          selectable={false}
          selectMirror={false}
          dateClick={handleSelection}
          events={events}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          datesSet={handleDatesSet}
          buttonText={{
            month: "Mês",
            week: "Semana",
            day: "Dia",
          }}
          buttonIcons={{
            prev: "chevron-left",
            next: "chevron-right",
          }}
          views={{
            dayGridMonth: {
              titleFormat: { year: "numeric", month: "long" },
            },
            timeGridWeek: {
              titleFormat: { year: "numeric", month: "long" },
              dayHeaderFormat: { day: "numeric" },
              allDaySlot: false,
            },
            timeGridDay: {
              titleFormat: { year: "numeric", month: "long", day: "2-digit" },
              allDaySlot: false,
            },
          }}
          dayMaxEventRows={5}
          fixedWeekCount={false}
          viewDidMount={handleViewDidMount}
          /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){}}
          eventRemove={function(){}}
          */
        />
        {currentView === "dayGridMonth" && eventsOfSelectedDate.length > 0 && (
          <div className="events-of-selected-date">
            <h4>
              {`Lembretes/Atendimentos do dia ${format(
                new Date(selectedDate),
                "dd/MM/yyyy"
              )}`}
            </h4>
            {eventsOfSelectedDate.map((event, index) => (
              <div
                key={event.id}
                className={cn("selected-date-event", {
                  "opacity-5": event?.preAgendamento,
                })}
                onClick={() => onEventClick(event)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onEventClick(event);
                  }
                }}
                role="button"
                tabIndex={index}
              >
                <div className="event-title">
                  <div
                    className="event-color-indicator"
                    style={{
                      backgroundColor:
                        event?.especialidade?.cor || event?.cor || "#0082c8",
                    }}
                  />
                  {`${
                    event.type === "atendimento"
                      ? `Atendimento ${
                          event?.preAgendamento ? "(Pré-agendamento)" : ""
                        } - ${event.cliente?.nome}`
                      : `Lembrete ${
                          event?.preAgendamento ? "(Pré-agendamento)" : ""
                        } - ${event.nome}`
                  }`}
                </div>
                <div className="d-flex justify-content-between">
                  <span className="event-time">
                    {`${format(new Date(event.start), "HH:mm")} - ${format(
                      new Date(event.end),
                      "HH:mm"
                    )}`}
                  </span>
                  {renderEventStatus(event)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="start">
        <DropdownToggle className="btn-add-agenda" outline>
          <i className="fas fa-plus text-primary" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            href={`${
              selectedDate
                ? `/agendamento?data=${selectedDate}`
                : "/agendamento"
            }`}
          >
            <i className="far fa-calendar-plus mr-2" />
            Novo Atendimento
          </DropdownItem>
          <DropdownItem
            href={`${
              selectedDate ? `/lembrete?data=${selectedDate}` : "/lembrete"
            }`}
          >
            <i className="fas fa-calendar-day mr-2" />
            Lembretes
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
