/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle, useCallback, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import { schema } from './schemaValidations';

function CustomModalMotivoCancelamento(_props, ref) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const { loading, setLoading, notificationRef } = useContext(AppContext);
  const { control, setValue, clearErrors, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  const onClose = useCallback(() => {
    setShow(false);
    clearErrors();
  }, []);

  useImperativeHandle(ref, () => ({
    show: (dataValues) => {
      setData(dataValues);
      setValue('motivoCancelamento', '');
      setShow(true);
    },
    close: () => onClose(),
  }));

  const onSuccess = useCallback((savedData) => {
    console.log('🚀 ~ file: CustomModalMotivoCancelamento index.js ~ onSuccess ~ savedData:', savedData);
    const { onSave } = data;
    if (typeof onSave === 'function') {
      onSave(savedData);
    }

    setShow(false);
  }, [data]);

  const onSubmit = useCallback((formValues) => {
    const { motivoCancelamento } = formValues;
    const { _id } = data;

    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Cancelando Agendamento...',
        });

        const res = await api.put(`/profissionalAgendamentoCancelamentos/${_id}`, { motivoCancelamento });
        setLoading(false);

        onSuccess(res.data);
        notificationRef.current.notify({
          message: "Agendamento cancelado com sucesso",
        });
      } catch (err) {
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao Cancelar Agendamento',
          color: 'danger',
        });
      }
    })();
  }, [data]);

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow(false)}
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <strong>Cancelar Atendimento</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShow(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <strong>Para cancelar um atendimento, por favor informe o motivo do cancelamento</strong>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Controller
                name="motivoCancelamento"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormGroup>
                    <Input
                      id="motivoCancelamento"
                      type="textarea"
                      placeholder="Motivo Cancelamento"
                      name={name}
                      value={value}
                      onChange={onChange}
                      invalid={!!errors?.motivoCancelamento?.message}
                    />
                    <FormFeedback>{errors?.motivoCancelamento?.message}</FormFeedback>
                  </FormGroup>
                )}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter tag="div" className="d-flex justify-content-end">
          <Button type="submit" color="success" disabled={loading?.loading || loading}>Confirmar</Button>
          <Button color="danger" className="ml-3" onClick={onClose} disabled={loading?.loading || loading}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default forwardRef(CustomModalMotivoCancelamento);
