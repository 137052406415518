/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Input,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';

import useApp from '../../hooks/useApp';
import api from '../../services/api';
import TimePickerMaskedInput from '../TimePickerMaskedInput';
import { schema } from './schemaValidations';

function CustomModalFinalizaAtendimento(_props, ref) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const { setLoading, notificationRef } = useApp();
  const { control, setValue, clearErrors, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  const onClose = useCallback(() => {
    setShow(false);
    clearErrors();
  }, []);

  useImperativeHandle(ref, () => ({
    show: (dataValues) => {
      setData(dataValues);
      setValue('dataHoraInicioAtendimento', dataValues?.dataHoraInicio ? new Date(dataValues?.dataHoraInicio) : new Date());
      setValue('dataHoraTerminoAtendimento', dataValues?.dataHoraTermino ? new Date(dataValues?.dataHoraTermino) : new Date());
      setValue('observacoes', '');
      setShow(true);
    },
    close: () => onClose(),
  }));

  const onSuccess = useCallback((savedData) => {
    console.log('🚀 ~ file: CustomModalFinalizaAtendimento index.js ~ onSuccess ~ savedData:', savedData);
    const { onSave } = data;
    if (typeof onSave === 'function') {
      onSave(savedData);
    }

    setShow(false);
  }, [data]);

  const onSubmit = useCallback((formValues) => {
    console.log(formValues);
    (async () => {
      try {
        setLoading(true);
        const dataSave = {
          cliente: data?.cliente,
          especialidade: data?.especialidade._id,
          dataHoraInicioAtendimento: formValues.dataHoraInicioAtendimento,
          dataHoraTerminoAtendimento: formValues.dataHoraTerminoAtendimento,
          observacoes: formValues.observacoes,
        };

        console.log(dataSave);
        await api.put(`/profissionalAtendimentos/${data?._id}`, dataSave);
        setLoading(false);
        notificationRef.current.notify({
          message: 'Atendimento finalizado com sucesso',
        });

        setValue('dataHoraInicioAtendimento', null);
        setValue('dataHoraTerminoAtendimento', null);
        setShow(false);
        onSuccess();
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao Finalizar Atendimento',
          color: 'danger',
        });
      }
    })();
  }, [data]);

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow(false)}
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <strong>Finalizar Atendimento</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShow(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Controller
            name="dataHoraInicioAtendimento"
            control={control}
            render={({ field }) => (
              <TimePickerMaskedInput
                label="Hora de início do atendimento"
                invalidMessage={errors?.dataHoraInicioAtendimento?.message}
                onChange={(e) => field.onChange(e)}
                selected={field.value}
              // includeTimes={includeTimes}
              />
            )}
          />
          <Controller
            name="dataHoraTerminoAtendimento"
            control={control}
            render={({ field }) => (
              <TimePickerMaskedInput
                label="Hora de término do atendimento"
                invalidMessage={errors?.dataHoraTerminoAtendimento?.message}
                onChange={(e) => field.onChange(e)}
                selected={field.value}
              // includeTimes={includeTimes}
              />
            )}
          />
          <Controller
            name="observacoes"
            control={control}
            render={({ field }) => (
              <Input
                type="textarea"
                placeholder="Observações"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit">Finalizar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default forwardRef(CustomModalFinalizaAtendimento);
