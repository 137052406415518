import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';

import './styles.css';

function CustomTable({
  columns = [],
  data = [],
  page = 1,
  loading = false,
  hasPrevPage = false,
  hasNextPage = false,
  paging = true,
  footer: Footer,
  onChangePage = () => null,
  onRowClick = () => null,
}) {
  if (loading) {
    return (
      <Table responsive className="custom-table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={`${column.dataField}-${index}`}
                style={{
                  verticalAlign: 'top',
                }}
              >
                <UncontrolledTooltip target={`#column-${column.id || column?.dataField?.replace(/./g, '-')}-${index}`} fade>
                  {column.text}
                </UncontrolledTooltip>
                <div id={`column-${column.id || column?.dataField?.replace(/./g, '-')}-${index}`} className="p-1 text-truncate">
                  {column.text}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 10 }).map((_, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={`${column.dataField}-${index}`} className="p-2">
                  <Skeleton height={20} width="100%" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <Table responsive className="custom-table">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th
              key={`${column.dataField}-${index}`}
              style={{
                verticalAlign: 'top',
                minWidth: column.width || '150px',
              }}
            >
              <UncontrolledTooltip target={`#column-${column.id || column?.dataField?.replace(/./g, '-')}-${index}`} fade>
                {column.text}
              </UncontrolledTooltip>
              <div id={`column-${column.id || column?.dataField?.replace(/./g, '-')}-${index}`} className="p-1 text-truncate">
                {column.text}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={`${row._id}-${index}`} onClick={() => onRowClick(row)}>
            {columns.map((column) => (
              <td key={column.dataField} className="p-2">
                {column.formatter ? column.formatter(row) : row[column.dataField]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {paging && !Footer && (
        <tfoot>
          <tr>
            <td colSpan={columns.length}>
              <Row>
                <Col className="d-flex justify-content-around pt-3">
                  <Button
                    color="primary"
                    onClick={() => onChangePage(page - 1)}
                    disabled={hasPrevPage}
                  >
                    Anterior
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => onChangePage(page + 1)}
                    disabled={hasNextPage}
                  >
                    Próximo
                  </Button>
                </Col>
              </Row>
            </td>
          </tr>
        </tfoot>
      )}
      {Footer && <Footer />}
    </Table>
  );
}

export default CustomTable;
