import React, { useState, useEffect, useCallback, useContext, useLayoutEffect } from 'react';
import {
  Col,
  Row,
  Container,
} from 'reactstrap';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import AgendamentoCard from '../../components/AgendamentoCard';
import { AppContext } from '../../contexts/app';
import useApp from '../../hooks/useApp';
import useAuth from '../../hooks/useAuth';
import api from '../../services/api';

function Home() {
  const { loggedUser } = useAuth();
  const { setLoading, notificationRef } = useApp();
  const [atendimentosEmAbertoAtrasados, setAtendimentoEmAbertoAtrasados] = useState([]);
  const { setHeaderTitle } = useContext(AppContext);

  useLayoutEffect(() => {
    setHeaderTitle('Seja Bem vindo');
  }, [setHeaderTitle]);

  useEffect(() => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Verificando atendimento em aberto atrasados...',
        });

        const { data } = await api.get('/profissionalAtendimentos');
        setAtendimentoEmAbertoAtrasados(data);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log('🚀 ~ file: Home Componente index.js ~ error:', err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef?.current?.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef?.current?.notify({
          message: 'Falha ao verificar atendimento em aberto atrasados',
          color: 'danger',
        });
      }
    })();
  }, [notificationRef]);

  const onAgendamentoCancel = useCallback(({ _id }) => {
    setAtendimentoEmAbertoAtrasados((prevStates = []) => prevStates.filter((a) => a._id !== _id));
  }, []);

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col>
          <div className="d-flex flex-column justify-content-center">
            {loggedUser?.name && <h5 className="text-center">{loggedUser.name}</h5>}
            <span className="text-center font-weight-600">{format(new Date(), "dd 'de' MMMM 'de' yyyy',' eeee", { locale: ptBR })}</span>
          </div>
        </Col>
      </Row>
      {atendimentosEmAbertoAtrasados.length > 0 ? (
        <>
          <Row className="mt-2">
            <Col>
              <h4 className="text-center text-warning">Atenção</h4>
              <h5 className="text-center text-warning">Você possui atendimento(s) em aberto e atrasado(s)</h5>
            </Col>
          </Row>
          <Row>
            {atendimentosEmAbertoAtrasados.map((agendamento) => (
              <Col key={agendamento._id} xs={12} lg={6}>
                <AgendamentoCard
                  agendamento={agendamento}
                  onAgendamentoCancel={onAgendamentoCancel}
                  isWarning
                  showDate
                />
              </Col>
            ))}
          </Row>
        </>
      ) : null}
    </Container>
  );
}

export default Home;
