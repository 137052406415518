/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { getDate, getHours, getMinutes, getMonth, getYear } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import '../CalendarEvents/styles.css';

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

function TimePickerMaskedInput({
  selected,
  date,
  title = 'Hora',
  label,
  placeholderText = 'hh:mm',
  dateFormat = 'HH:mm',
  locale = 'ptBR',
  invalidMessage = '',
  onChange,
  ...props
}) {
  return (
    <DatePicker
      title={title}
      placeholderText={placeholderText}
      dateFormat={dateFormat}
      locale={locale}
      selected={selected}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={5}
      timeCaption="Hora"
      onChange={(d) => {
        const newDate = new Date(
          getYear(date || new Date()),
          getMonth(date || new Date()),
          getDate(date || new Date()),
          getHours(d),
          getMinutes(d),
        );
        // console.log(newDate);
        onChange(newDate);
      }}
      customInput={(
        <InputMask mask="99:99" placeholder="hh:mm">
          {(inputProps) => (
            <FormGroup>
              {label ? (
                <Label for={`time-picker-${props.id}`}>{label}</Label>
              ) : null}
              <Input
                id={`time-picker-${props.id}`}
                className="w-100"
                invalid={!!invalidMessage}
                {...inputProps}
              />
              <FormFeedback className="text-danger">
                {invalidMessage}
              </FormFeedback>
            </FormGroup>
          )}
        </InputMask>
        )}
      {...props}
    />
  );
}

export default TimePickerMaskedInput;
