import React, { useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

import { AppContext } from '../../../contexts/app';
import { useAuth } from '../../../hooks/useAuth';
import useNotification from '../../../hooks/useNotification';

function Topbar({ sidebarIsShow, toggleSidebar }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading, headerTitle } = useContext(AppContext);
  const { unreadNotifications } = useNotification();
  const { signOut } = useAuth();

  const handleLogout = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        navigate('/');
        await signOut();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="d-flex flex-column shadow-sm p-3 mb-2 rounded">
      <div className="d-flex justify-content-between">
        <div>
          {!sidebarIsShow && (
            <Button color="danger" onClick={toggleSidebar}>
              <i className="fa-solid fa-bars text-white" />
            </Button>
          )}
          {location.pathname !== '/' && (
            <Button color="danger" className="ml-3 text-white" onClick={() => navigate(-1)}>
              <i className="fa-solid fa-angles-left" />
            </Button>
          )}
          {unreadNotifications > 0 && (
            <Button color="danger" className="ml-3 text-white position-relative" onClick={() => navigate('/notificacoes')}>
              <i className="fa-solid fa-bell" />
              <span className="badge bg-white text-dark position-absolute top--2 right--1">{unreadNotifications}</span>
            </Button>
          )}
        </div>
        <div className="d-none d-sm-flex flex-column justify-content-center">
          <h3 className="text-center font-weight-700">{headerTitle}</h3>
        </div>
        <Button color="danger" onClick={handleLogout}>
          <i className="fa-solid fa-power-off" />
        </Button>
      </div>
      <div className="d-flex flex-column d-sm-none">
        <h3 className="text-center font-weight-700">{headerTitle}</h3>
      </div>
    </div>
  );
}

export default Topbar;
