import * as Yup from 'yup';

export const schema = Yup.object().shape({
  cliente: Yup.string().required('Selecione um cliente'),
  salaAtendimento: Yup.string().required('Selecione uma sala de atendimento'),
  especialidade: Yup.string().required('Selecione uma especialidade'),
  dataAtendimento: Yup.string().required('Seleciona uma data atendimento'),
  dataHoraInicio: Yup.string().required('Seleciona uma hora para o início do atendimento'),
  dataHoraTermino: Yup.string().required('Seleciona uma hora para o término do atendimento'),
});
