/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState, useContext, useLayoutEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Label,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { addHours, format, getDate, getHours, getMinutes, getMonth, getYear, parseISO } from 'date-fns';

import CustomSelectSearch from '../../components/CustomSelectSearch';
import DateTimePickerInput from '../../components/DateTimePickerInput';
// import CustomSelect from '../../components/CustomSelect';
// import TimePickerMaskedInput from '../../components/TimePickerMaskedInput';
import { AppContext } from '../../contexts/app';
import useApp from '../../hooks/useApp';
import useAuth from '../../hooks/useAuth';
import useGenerateIntervalHours from '../../hooks/useGenerateIntervalHours';
import api from '../../services/api';
import { schema } from './validationSchema';

function Agendamento() {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setLoading, notificationRef } = useApp();
  const { loggedUser } = useAuth();
  const { generateIntervalHours } = useGenerateIntervalHours();
  const [includeTimes, setIncludeTimes] = useState([]);
  const [salasAtendimentos, setSalasAtendimentos] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [loadingParticipantes, setLoadingParticipantes] = useState(false);
  const [daysOfCalendar, setDaysOfCalendar] = useState([]);
  const [disponibilidades, setDisponibilidades] = useState([]);
  const [isPreAgendamento, setIsPreAgendamento] = useState(false);
  const { control, watch, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      salaAtendimento: '',
      especialidade: '',
      status: 'aberto',
      dataHoraInicio: '',
      dataHoraTermino: '',
      repetir: 'padrao',
      dataRepetirTermino: '',
      participantes: [],
      cliente: '',
      observacoes: '',
    },
    resolver: yupResolver(schema)
  });
  const watchSalaAtendimento = watch('salaAtendimento');
  const watchDataAtendimento = watch('dataAtendimento');
  const watchEspecialidade = watch('especialidade');
  const watchDataHoraInicio = watch('dataHoraInicio');
  const watchDataHoraTermino = watch('dataHoraTermino');
  const watchCliente = watch('cliente');
  const watchRepetir = watch('repetir');
  const { setHeaderTitle } = useContext(AppContext);

  useLayoutEffect(() => {
    setHeaderTitle('Atendimento');
  }, [setHeaderTitle]);

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/salaAtendimentos?limit=0');
        setSalasAtendimentos(data.map((d) => ({
          value: d._id,
          label: d.nome,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const especialidade = watchEspecialidade;
        if (especialidade) {
          setLoadingClientes(true);
          const { data = [] } = await api.get(`/profissionalClientes?tipoDeAtendimento=${especialidade}`);
          // console.log(data);
          setClientes(data.map((d) => ({
            value: d._id,
            cpf: d.criancas.cpf,
            label: d.criancas.nome,
            adminFaturamento: d.adminFaturamento,
          })));
          setLoadingClientes(false);
        }
      } catch (error) {
        setLoadingClientes(false);
        console.log(error);
      }
    })();
  }, [watchEspecialidade]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingParticipantes(true);
        const { data = [] } = await api.get(`/profissionalParticipantes`);
        setParticipantes(data.map((d) => ({
          value: d.id,
          label: `${d.name} (${d.type})`,
          type: d.type,
        })));
        setLoadingParticipantes(false);
      } catch (error) {
        setLoadingParticipantes(false);
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    const salaAtendimento = watchSalaAtendimento;
    console.log('🚀 ~ useEffect ~ salaAtendimento:', salaAtendimento);
    if (salaAtendimento) {
      (async () => {
        try {
          setLoading(true);
          const firstDayOfCalendar = daysOfCalendar[0];
          const lastDayOfCalendar = daysOfCalendar[daysOfCalendar.length - 1];

          // console.log(firstDayOfCalendar, lastDayOfCalendar);
          if (firstDayOfCalendar && lastDayOfCalendar) {
            const { data = [] } = await api.get(`/salaAtendimentoDisponibilidades/${salaAtendimento}?dataHoraInicio=${firstDayOfCalendar}&dataHoraTermino=${lastDayOfCalendar}`);
            // console.log(data);
            setDisponibilidades(data.map((d) => ({ ...d, startDate: d.dataHoraInicio, endDate: d.dataHoraTermino })));
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      })();
    }
  }, [daysOfCalendar, watchSalaAtendimento]);

  useEffect(() => {
    setIncludeTimes([]);
    if (disponibilidades?.length && watchDataAtendimento) {
      const date = watchDataAtendimento;

      /* console.log('disponibilidade', date, disponibilidades.find((d) => getDate(new Date(d.startDate)) === getDate(date)
        && getMonth(new Date(d.startDate)) === getMonth(date)
        && getYear(new Date(d.startDate)) === getYear(date))); */
      const disponibilidadesFiltered = disponibilidades.filter((d) => getDate(new Date(d.startDate)) === getDate(date)
        && getMonth(new Date(d.startDate)) === getMonth(date)
        && getYear(new Date(d.startDate)) === getYear(date));
      // console.log('🚀 ~ file: index.js:109 ~ useEffect ~ disponibilidadesFiltered:', disponibilidadesFiltered);

      if (disponibilidadesFiltered?.length) {
        const avaibilitiesTimes = [];
        for (const disponibilidade of disponibilidadesFiltered) {
          avaibilitiesTimes.push(...generateIntervalHours(new Date(disponibilidade.startDate), getHours(new Date(disponibilidade.startDate)), getHours(new Date(disponibilidade.endDate)), getMinutes(new Date(disponibilidade.startDate)), getMinutes(new Date(disponibilidade.endDate))));
        }
        setIncludeTimes(avaibilitiesTimes);
      }
    }
  }, [watchDataAtendimento, disponibilidades]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('/getEspecialistas');
        setEspecialidades(data.especialidades.map((e) => ({
          value: e._id,
          label: e.nome,
          cor: e.cor,
        })));
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    })();
  }, []);

  useEffect(() => {
    setValue('dataHoraInicio', '');
    setValue('dataHoraTermino', '');
  }, [watchDataAtendimento]);

  /* useEffect(() => {
    console.log('🚀 ~ useEffect ~ watchDataAtendimento:', watchDataAtendimento);
    console.log('🚀 ~ useEffect ~ watchDataHoraInicio:', watchDataHoraInicio);
    try {
      if (watchDataHoraInicio && watchDataAtendimento && !watchDataHoraTermino) {
        const dataHoraInicioAddedOneHour = typeof watchDataHoraInicio === 'string'
          ? addHours(new Date(getYear(watchDataAtendimento), getMonth(watchDataAtendimento), getDate(watchDataAtendimento), Number(`${watchDataHoraInicio}`.split(':')[0]), Number(`${watchDataHoraInicio}`.split(':')[1])), 1)
          : addHours(watchDataHoraInicio, 1);
        console.log('🚀 ~ useEffect ~ dataHoraInicioAddedOneHour:', dataHoraInicioAddedOneHour);
        setValue('dataHoraTermino', format(dataHoraInicioAddedOneHour, 'HH:mm'));
      }
    } catch (error) {
      console.log('🚀 ~ useEffect ~ error:', error);
    }
  }, [watchDataHoraInicio, watchDataAtendimento, watchDataHoraTermino]); */

  useEffect(() => {
    setIsPreAgendamento(false);
    console.log("🚀 ~ useEffect ~ params?.data:", searchParams.get('data'));
    if (searchParams.get('data')) {
      setValue('dataAtendimento', new Date(searchParams.get('data')));
    }
    if (params?.id) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await api.get(`/profissionalAgendamentos/${params.id}`);

          setValue('salaAtendimento', data?.salaAtendimento?._id ? data?.salaAtendimento?._id : '');
          setValue('especialidade', data?.especialidade?._id ? data.especialidade._id : '');
          setValue('cliente', data?.cliente?.id ? data.cliente.id : '');
          setValue('participantes', data?.participantes?.map((p) => p.id) || []);
          setValue('dataAtendimento', data?.dataHoraInicio ? parseISO(data.dataHoraInicio) : null);
          setValue('dataHoraInicio', data?.dataHoraInicio ? format(parseISO(data.dataHoraInicio), 'HH:mm') : '');
          setValue('dataHoraTermino', data?.dataHoraTermino ? format(parseISO(data.dataHoraTermino), 'HH:mm') : '');
          setValue('dataRepetirTermino', data?.dataRepetirTermino ? parseISO(data.dataRepetirTermino) : null);
          setValue('repetir', data?.repetir || 'padrao');
          setValue('observacoes', data?.observacoes || '');
          setIsPreAgendamento(data?.preAgendamento);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      })();
    }
  }, [params]);

  const onSubmit = (formValues) => {
    console.log(formValues);
    (async () => {
      try {
        setLoading(true);
        const _cliente = clientes.find((c) => c.value === formValues.cliente);
        const data = {
          cliente: {
            id: _cliente.value,
            cpf: _cliente.cpf,
            nome: _cliente.label,
          },
          participantes: participantes.filter((p) => formValues.participantes?.includes(p.value)).map((p) => ({
            id: p.value,
            name: p.label,
            type: p.type,
          })),
          profissional: loggedUser._id,
          salaAtendimento: formValues.salaAtendimento,
          especialidade: formValues.especialidade,
          dataHoraInicio: new Date(getYear(watchDataAtendimento), getMonth(watchDataAtendimento), getDate(watchDataAtendimento), Number(formValues.dataHoraInicio.split(':')[0]), Number(formValues.dataHoraInicio.split(':')[1])),
          dataHoraTermino: new Date(getYear(watchDataAtendimento), getMonth(watchDataAtendimento), getDate(watchDataAtendimento), Number(formValues.dataHoraTermino.split(':')[0]), Number(formValues.dataHoraTermino.split(':')[1])),
          dataRepetirTermino: formValues.dataRepetirTermino,
          repetir: formValues.repetir,
          observacoes: formValues.observacoes,
        };
        console.log('🚀 ~ data:', data);

        if (params?.id) {
          await api.put(`/profissionalAgendamentos/${params.id}`, data);
          /* setTimeout(() => {
            navigate('/');
          }, 1000); */
          setTimeout(() => {
            setLoading(false);
            notificationRef.current.notify({
              message: 'Agendamento atualizado com sucesso',
            });
            navigate(`/agenda`);
          }, 500);
          return;
        }

        const { data: newAgendamento } = await api.post('/profissionalAgendamentos', data);

        /* setTimeout(() => {
          navigate(`/agendamento/${newAgendamento._id}`);
        }, 1000); */
        setTimeout(() => {
          setLoading(false);
          notificationRef.current.notify({
            message: 'Agendamento realizado com sucesso',
          });
          navigate(`/agenda`);
        }, 500);
      } catch (err) {
        console.log('🚀 ~ err:', err);
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar Agendamento',
          color: 'danger',
        });
      }
    })();
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Row className="mt-2">
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* <CustomSelect
                  label="Sala de Atendimento"
                  placeholder="Selecione uma Sala de Atendimento"
                  invalidMessage={errors?.salaAtendimento?.value?.message}
                  {...field}
                  options={salasAtendimentos}
                /> */}
                <Controller
                  name="salaAtendimento"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="salaAtendimentoSelect">
                        Sala de Atendimento
                      </Label>
                      <Input
                        id="salaAtendimentoSelect"
                        type="select"
                        name={name}
                        value={value}
                        onChange={onChange}
                        invalid={!!errors?.salaAtendimento?.message}
                      >
                        <option value="">Selecione uma Sala de Atendimento</option>
                        {salasAtendimentos.map((sala) => (
                          <option key={sala.value} value={sala.value}>
                            {sala.label}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>{errors?.salaAtendimento?.message}</FormFeedback>
                    </FormGroup>
                  )}
                />
                <Controller
                  name="dataAtendimento"
                  control={control}
                  render={({ field }) => (
                    <DateTimePickerInput
                      label="Data do Atendimento"
                      invalidMessage={errors?.dataAtendimento?.message}
                      events={disponibilidades}
                      onDaysOfCalendarChange={(dates) => setDaysOfCalendar(dates)}
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      showTimeInput={false}
                    />
                  )}
                />
                <Row>
                  <Col xs={12} sm={6}>
                    {/* <TimePickerMaskedInput
                        label="Hora de início do atendimento"
                        date={watchDataAtendimento}
                        invalidMessage={errors?.dataHoraInicio?.message}
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                        includeTimes={includeTimes}
                      /> */}
                    <Controller
                      name="dataHoraInicio"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <FormGroup>
                          <Label for="dataHoraInicioSelect">
                            Hora de início do atendimento
                          </Label>
                          <Input
                            id="dataHoraInicioSelect"
                            type="select"
                            name={name}
                            value={value}
                            onChange={onChange}
                            invalid={!!errors?.dataHoraInicio?.message}
                          >
                            <option value="">Selecione uma Hora</option>
                            {includeTimes.map((time, index) => (
                              <option key={`${time}-${index}`} value={format(time, 'HH:mm')}>
                                {format(time, 'HH:mm')}
                              </option>
                            ))}
                          </Input>
                          <FormFeedback>{errors?.dataHoraInicio?.message}</FormFeedback>
                        </FormGroup>
                      )}
                    />
                  </Col>
                  {/* <TimePickerMaskedInput
                    label="Hora de término do atendimento"
                    date={watchDataAtendimento}
                    invalidMessage={errors?.dataHoraTermino?.message}
                    onChange={(e) => field.onChange(e)}
                    selected={field.value}
                    includeTimes={includeTimes}
                  /> */}
                  <Col xs={12} sm={6}>
                    <Controller
                      name="dataHoraTermino"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <FormGroup>
                          <Label for="dataHoraTerminoSelect">
                            Hora de término do atendimento
                          </Label>
                          <Input
                            id="dataHoraTerminoSelect"
                            type="select"
                            name={name}
                            value={value}
                            onChange={onChange}
                            invalid={!!errors?.dataHoraTermino?.message}
                          >
                            <option value="">Selecione uma Hora</option>
                            {includeTimes.map((time, index) => (
                              <option key={`${time}-${index}`} value={format(time, 'HH:mm')}>
                                {format(time, 'HH:mm')}
                              </option>
                            ))}
                          </Input>
                          <FormFeedback>{errors?.dataHoraTermino?.message}</FormFeedback>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                {/* <CustomSelect
                  label="Tipo de Atendimento"
                  text="Selecione um do(s) seu(s) tipo(s) de atendimento para atender o paciente"
                  placeholder="Selecione um tipo de atendimento"
                  invalidMessage={errors?.especialidade?.value?.message}
                  {...field}
                  options={especialidades}
                /> */}
                {/* <CustomSelect
                  label="Paciente"
                  placeholder="Selecione um Paciente"
                  loading={loadingClientes}
                  loadingMessage={() => 'Carregando pacientes de acordo com o tipo de atendimento selecionado'}
                  noOptionsMessage={() => (!clientes.length && watchEspecialidade
                    ? 'Nenhum paciente encontrado de acordo com o tipo de atendimento selecionado'
                    : 'Selecione um tipo de atendimento')}
                  invalidMessage={errors?.cliente?.value?.message}
                  {...field}
                  options={clientes}
                /> */}
                {!isPreAgendamento && (
                  <>
                    <Controller
                      name="repetir"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <FormGroup tag="fieldset">
                          <Label>
                            Repetir Atendimento
                          </Label>
                          <FormGroup check>
                            <Input
                              name={name}
                              type="radio"
                              value="padrao"
                              checked={value === 'padrao'}
                              onChange={onChange}
                            />
                            {' '}
                            <Label check>
                              Não Repetir
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name={name}
                              type="radio"
                              value="diario"
                              checked={value === 'diario'}
                              onChange={onChange}
                            />
                            {' '}
                            <Label check>
                              Diariamente
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name={name}
                              type="radio"
                              value="semanal"
                              checked={value === 'semanal'}
                              onChange={onChange}
                            />
                            {' '}
                            <Label check>
                              Semanalmente
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name={name}
                              type="radio"
                              value="mensal"
                              checked={value === 'mensal'}
                              onChange={onChange}
                            />
                            {' '}
                            <Label check>
                              Mensalmente
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      )}
                    />
                    {watchRepetir !== 'padrao' && (
                      <Controller
                        name="dataRepetirTermino"
                        control={control}
                        render={({ field: { onChange, name, value } }) => (
                          <DateTimePickerInput
                            label="Data de término da repetição"
                            invalidMessage={errors?.dataRepetirTermino?.message}
                            onChange={(e) => onChange(e)}
                            selected={value}
                            showTimeInput={false}
                          />
                        )}
                      />
                    )}
                  </>
                )}
                <Controller
                  name="especialidade"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="especialidadeSelect">
                        Tipo de Atendimento
                      </Label>
                      <CustomSelectSearch
                        id="especialidadeSelect"
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={especialidades}
                        placeholder="Selecione um Tipo de Atendimento"
                        invalid={!!errors?.especialidade?.message}
                        errorMessage={errors?.especialidade?.message}
                        loadingMessage="Carregando tipos de atendimento"
                        noOptionsMessage="Nenhum tipo de atendimento encontrado"
                        onRenderLabel={(option) => (
                          <div className="d-flex align-items-center">
                            <div style={{ backgroundColor: option.cor, width: '20px', height: '20px', borderRadius: '10px', marginRight: '0.5rem' }} />
                            {option.label}
                          </div>
                        )}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="cliente"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="clienteSelect">
                        Paciente
                      </Label>
                      <CustomSelectSearch
                        id="clienteSelect"
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={clientes}
                        placeholder="Selecione um Paciente"
                        invalid={!!errors?.cliente?.message}
                        errorMessage={errors?.cliente?.message}
                        isLoading={loadingClientes}
                        loadingMessage="Carregando pacientes de acordo com o tipo de atendimento selecionado"
                        noOptionsMessage={
                          !clientes.length && watchEspecialidade
                            ? 'Nenhum paciente encontrado de acordo com o tipo de atendimento selecionado'
                            : 'Selecione um tipo de atendimento'
                        }
                      />
                    </FormGroup>
                  )}
                />
                <FormGroup>
                  <Label for="adminFaturamento">
                    Responsável Faturamento
                  </Label>
                  <Input
                    id="adminFaturamento"
                    type="text"
                    name="adminFaturamento"
                    value={clientes.find((c) => c.value === watchCliente)?.adminFaturamento || ''}
                    readOnly
                  />
                </FormGroup>
                <Controller
                  name="participantes"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="participantesSelect">
                        Convidar Participantes (Campo não obrigatório e a notificação no sistema não substitui o convite formal. Envie também por e-mail ou WhatsApp)
                      </Label>
                      <CustomSelectSearch
                        id="participantesSelect"
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={participantes}
                        placeholder="Selecione um ou mais Participantes"
                        invalid={!!errors?.participantes?.message}
                        errorMessage={errors?.participantes?.message}
                        isLoading={loadingParticipantes}
                        loadingMessage="Carregando participantes"
                        noOptionsMessage="Nenhum participante encontrado"
                        isMultiple
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="observacoes"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="observacoes">
                        Observações
                      </Label>
                      <Input
                        id="observacoes"
                        type="textarea"
                        name={name}
                        value={value}
                        onChange={onChange}
                        invalid={!!errors?.observacoes?.message}
                      />
                      <FormFeedback>{errors?.observacoes?.message}</FormFeedback>
                    </FormGroup>
                  )}
                />
                <Button type="submit" className="my-4" color="success" block>
                  {params?.id ? 'Alterar Agendamento' : 'Agendar'}
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Agendamento;
