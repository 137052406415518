/* eslint-disable consistent-return */
import React, { createContext, useEffect, useMemo, useState } from "react";

import { io } from "socket.io-client";

import useAuth from "../hooks/useAuth";

let _socket = null; // Singleton para o socket

const baseUrlSocket = process.env.REACT_APP_SOCKET_URL;

export const SocketContext = createContext();

function SocketProvider({ children }) {
  const { loggedUser } = useAuth();
  const [socket, setSocket] = useState(null);
  const [socketId, setSocketId] = useState(undefined);
  const [isConnected, setIsConnected] = useState(false);
  const [transport, setTransport] = useState("N/A");

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ loggedUser:", loggedUser);
    console.log("🚀 ~ baseUrlSocket:", baseUrlSocket);
    if (!loggedUser?.token) return;
    // Inicializa o socket apenas uma vez
    if (!_socket) {
      _socket = io(baseUrlSocket, {
        auth: { token: loggedUser?.token },
        transports: ["websocket", "polling"],
      });
      setSocket(_socket);
    }

    const onConnect = () => {
      setSocketId(_socket?.id);
      console.log("🚀 ~ useEffect ~ _socket:", _socket?.id);
      setIsConnected(true);
      setTransport(_socket?.io.engine.transport.name || "N/A");

      _socket?.io.engine.on("upgrade", (_transport) => {
        setTransport(_transport.name);
      });
    };

    const onDisconnect = () => {
      setIsConnected(false);
      setTransport("N/A");
    };

    _socket.on("connect", onConnect);
    _socket.on("disconnect", onDisconnect);

    return () => {
      // Remove os listeners para evitar múltiplos registros
      _socket?.off("connect", onConnect);
      _socket?.off("disconnect", onDisconnect);
    };
  }, [loggedUser?.token]); // Depende apenas do token

  const value = useMemo(
    () => ({ isConnected, transport, socket, socketId }),
    [isConnected, transport, socket, socketId]
  );

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
}

export default SocketProvider;
