import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Collapse,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import cn from "classnames";
import { format, parseISO } from 'date-fns';

import Modal from '../../components/AlertModal';
import { AppContext } from '../../contexts/app';
import useNotification from '../../hooks/useNotification';

function Notifications() {
  const modalRef = useRef();
  const { loading, notifications, onMarkAsRead, onDelete, onAccept, onReject, onArchive, onRestore, onFilter, filters, setFilters, onClearFilters } = useNotification();
  const { setHeaderTitle } = useContext(AppContext);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  useLayoutEffect(() => {
    setHeaderTitle('Notificações');
  }, [setHeaderTitle]);

  const handleDelete = async (n) => {
    modalRef.current.show({
      title: 'Excluir Notificação',
      message: 'Tem certeza que deseja excluir esta notificação?',
      confirmText: 'Sim',
      onConfirm: async () => {
        await onDelete(n);
        modalRef?.current?.close();
      },
      cancelText: 'Não',
    });
  };

  const handleOpenModal = (n) => {
    modalRef.current.show({
      title: n.title,
      message: n.message,
      hideConfirm: n.status === 'lido' || n.status === 'arquivado',
      confirmText: 'Marcar como lido',
      onConfirm: () => onMarkAsRead(n),
      cancelText: 'Excluir',
      onCancel: () => handleDelete(n),
    });
  };

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-items-center text-center" style={{ height: '100vh' }}>
              Carregando...
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (notifications.length === 0) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-center align-items-center text-center" style={{ height: 'calc(100vh - 100px)' }}>
              <i className="fa-solid fa-bell-slash text-muted" style={{ fontSize: '10rem' }} />
              <h5 className="mt-3">{Object.keys(filters).length > 0 ? 'Nenhuma notificação encontrada com os filtros aplicados' : 'Por enquanto não há notificações'}</h5>
              {Object.keys(filters).length > 0 && (
                <Button color="primary" onClick={onClearFilters}>Limpar Filtros</Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Modal ref={modalRef} />
      <Row>
        <Col>
          <Button color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}>
            Filtrar Notificações
          </Button>
          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>
                <Form>
                  <Row>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <Label for="tipoSelect">Tipo de Notificação</Label>
                        <Input type="select" name="tipo" id="tipoSelect" value={filters.tipo} onChange={(e) => setFilters({ ...filters, tipo: e.target.value })}>
                          <option value="">Selecione um Tipo</option>
                          <option value="lembrete">Lembrete</option>
                          <option value="atendimento">Atendimento</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <Label for="statusSelect">Status</Label>
                        <Input type="select" name="status" id="statusSelect" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
                          <option value="">Selecione um Status</option>
                          <option value="lido">Lido</option>
                          <option value="naoLido">Não Lido</option>
                          <option value="arquivado">Arquivado</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <Label for="dataSelect">Data do Recebimento De</Label>
                        <Input type="date" name="data" id="dataSelect" value={filters.dataDe} onChange={(e) => setFilters({ ...filters, dataDe: e.target.value })} />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <Label for="dataSelect">Data do Recebimento Até</Label>
                        <Input type="date" name="data" id="dataSelect" value={filters.dataAte} onChange={(e) => setFilters({ ...filters, dataAte: e.target.value })} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-end">
                        <Button color="primary" className="mr-2" onClick={() => onFilter(filters)}>Filtrar</Button>
                        <Button color="secondary" onClick={onClearFilters}>Limpar</Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex flex-column">
            {notifications.map((n) => (
              <Card
                key={n._id}
                className={cn("cursor-pointer", { "opacity-6": n.status === 'arquivado' })}
              >
                <CardHeader onClick={() => handleOpenModal(n)} className="d-flex flex-column">
                  <h5 className={cn("mb-0 text-ellipsis overflow-hidden text-nowrap text-truncate font-weight-bold", { "text-muted font-weight-normal": n.status === 'lido' || n.status === 'arquivado' })}>{n.title}</h5>
                  <span className={cn("text-muted small font-weight-bold", { "font-weight-normal": n.status === 'lido' || n.status === 'arquivado' })}>{`Recebido em ${format(parseISO(n.createdAt), 'dd/MM/yyyy HH:mm')}`}</span>
                  {n.accepted && n.acceptedAt && <span className={cn("text-muted small font-weight-bold", { "font-weight-normal": n.status === 'lido' || n.status === 'arquivado' })}>{`Aceito em ${format(parseISO(n.acceptedAt), 'dd/MM/yyyy HH:mm')}`}</span>}
                  {!n.accepted && n.rejectedAt && <span className={cn("text-muted small font-weight-bold", { "font-weight-normal": n.status === 'lido' || n.status === 'arquivado' })}>{`Rejeitado em ${format(parseISO(n.rejectedAt), 'dd/MM/yyyy HH:mm')}`}</span>}
                  {n.status === 'arquivado' && <span className={cn("text-muted small font-weight-bold", { "font-weight-normal": n.status === 'lido' || n.status === 'arquivado' })}>{`Arquivado em ${format(parseISO(n.archivedAt), 'dd/MM/yyyy HH:mm')}`}</span>}
                </CardHeader>
                <CardBody onClick={() => handleOpenModal(n)}>
                  <p className={cn("text-ellipsis overflow-hidden text-nowrap text-truncate font-weight-normal", { "text-muted font-weight-normal": n.status === 'lido' || n.status === 'arquivado' })}>{n.message}</p>
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end">
                    {n.type === 'evento' && !n.acceptedAt && !n.rejectedAt && n.status !== 'arquivado' && (
                      <>
                        <Button
                          color="success"
                          className="mr-2"
                          onClick={() => onAccept(n)}
                        >
                          Aceitar
                        </Button>
                        <Button
                          color="danger"
                          className="mr-2"
                          onClick={() => onReject(n)}
                        >
                          Rejeitar
                        </Button>
                      </>
                    )}
                    {n.status !== 'lido' && n.status !== 'arquivado' && n.type !== 'evento' && (
                      <Button
                        color="primary"
                        className="mr-2"
                        onClick={() => onMarkAsRead(n)}
                      >
                        Lido
                      </Button>
                    )}
                    {(n.type !== 'evento' || (n.type === 'evento' && (n.acceptedAt || n.rejectedAt))) && (
                      <Button
                        color="danger"
                        className="mr-2"
                        onClick={() => handleDelete(n)}
                      >
                        Excluir
                      </Button>
                    )}
                    {n.type === 'evento' && n.status === 'arquivado' && (
                      <Button
                        color="danger"
                        className="mr-2"
                        onClick={() => handleDelete(n)}
                      >
                        Excluir
                      </Button>
                    )}
                    {n.status !== 'arquivado' && (
                      <Button
                        color="dark"
                        className="mr-2"
                        onClick={() => onArchive(n)}
                      >
                        Arquivar
                      </Button>
                    )}
                    {n.status === 'arquivado' && (
                      <Button
                        color="dark"
                        className="mr-2"
                        onClick={() => onRestore(n)}
                      >
                        Restaurar
                      </Button>
                    )}
                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Notifications;
