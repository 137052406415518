export const numberToCurrency = (number) => {
  if (number === null || number === undefined) return '';

  return number.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const normalizeTextForSearch = (text) => {
  if (!text) return '';
  if (typeof text !== 'string') return '';
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "");
};
