import { validateCPF } from 'validations-br';
import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Informe o Nome Completo'),
  cpf: Yup.string()
    .test('is-valid-cpf', 'CPF inválido', (value) => validateCPF(value))
    .required('Informe um CPF'),
  email: Yup.string().email('Email inválido').required('Informe um email'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas precisam ser iguais',
  ),
});
