/* eslint-disable eqeqeq */
import { useCallback } from 'react';

function useSecondsToDhms() {
  const convertSecondsToDhms = useCallback((seconds) => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    // console.log(d, h, m, s)
    const dDisplay = d > 0 ? d + (d == 1 ? ' dia, ' : ' dias, ') : '';
    const hDisplay = h > 0 ? h + (h == 1 ? ' hora, ' : ' horas, ') : '';
    const mDisplay = m > 0 ? m + (m == 1 ? ' minuto, ' : ' minutos, ') : '';
    const sDisplay = s > 0 ? s + (s == 1 ? ' segundo, ' : ' segundos, ') : '';
    const formattedStr = `${dDisplay}${hDisplay}${mDisplay}${sDisplay}`;
    return formattedStr.substring(0, formattedStr.length - 2);
  }, []);

  return {
    convertSecondsToDhms,
  };
}

export default useSecondsToDhms;
