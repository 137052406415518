import ForgetPassword from '../pages/ForgetPassword';
import Login from '../pages/Login';
import RecoverPassword from '../pages/RecoverPassword';
import Register from '../pages/Register';

export default [
  { path: '/', Component: Login },
  { path: '/login', Component: Login },
  { path: '/cadastro', Component: Register },
  { path: '/esquecisenha', Component: ForgetPassword },
  { path: '/alterarsenha/:token', Component: RecoverPassword },
  { path: '*', Component: Login },
];
