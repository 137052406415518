import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import logocompleto from '../../assets/img/logocompleto.png';
import { useAuth } from '../../hooks/useAuth';

function Header() {
  const { isAuthenticated, loggedUser } = useAuth();
  const navigate = useNavigate();
  const [togglerOpen, setTogglerOpen] = useState(false);

  return (
    <Row className="bg-primary px-2 py-2 px-sm-4 align-items-center">
      <Col xs={12} lg={5}>
        <Row className="align-items-center">
          <Col xs={10} md={12}>
            <Link to="/">
              <img src={logocompleto} alt="NIC" height="75px" />
            </Link>
          </Col>
          <Col xs={2} className="d-md-none">
            <Button
              color="link"
              onClick={() => setTogglerOpen((prevState) => !prevState)}
            >
              <i className="fas fa-bars text-white" />
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={12} lg={7} className={`${togglerOpen ? '' : 'd-none'}`}>
        <Row>
          <Col>
            <div className="d-flex flex-wrap justify-content-center justify-content-md-end align-items-center">
              {isAuthenticated ? (
                <Button
                  className={`text-white m-2 ${togglerOpen ? '' : 'd-none'}`}
                  color="link"
                  onClick={() => navigate('/usuario')}
                >
                  Bem-Vindo
                  {' '}
                  {loggedUser?.name}
                  <i className="fas fa-user p-1" />
                </Button>
              ) : null}
              {!isAuthenticated && (
                <Button
                  className={`m-2 text-white font-weight-bold ${
                    togglerOpen ? '' : 'd-none'
                  }`}
                  color="link"
                  onClick={() => navigate('/login')}
                >
                  Entrar
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Header;
