/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { NavItem, NavLink, Nav, Button } from 'reactstrap';

import classNames from 'classnames';

import logocompleto from '../../../assets/img/logocompleto.png';
import MenuItem from './MenuItem';
import navigation from './navigation';
// import SubMenu from './SubMenu';

import './styles.css';

function SideBar({ isOpen, isShow, toggleSidebarOpen, toggleSidebarShow }) {
  const location = useLocation();
  const params = useParams();

  return (
    <div className={classNames('sidebar', { 'is-open': isOpen, 'no-show': !isShow })}>
      <div className="sidebar-header position-relative">
        <div className="d-flex flex-wrap justify-content-around w-100">
          <Button className="mt-2" color="link" onClick={toggleSidebarShow}>
            <i className="fa-solid fa-xmark text-dark" style={{ fontSize: '3rem' }} />
          </Button>
          <Button className="mt-2" color="link" onClick={toggleSidebarOpen}>
            <i className="fa-solid fa-bars text-dark" style={{ fontSize: '3rem' }} />
          </Button>
        </div>
        <Link to="/">
          <img
            src={logocompleto}
            className="mt-2"
            alt="nic"
          />
        </Link>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          {navigation.map(({ to, icon: Icon, name, description }) => (
            <NavItem
              title={name}
              key={`${to}`}
              className={classNames('items', {
                active: location.pathname === to
                  || `${location.pathname}` === `${to}/${params.id}`,
              })}
            >
              <NavLink tag={Link} className={classNames({ 'is-closed': !isOpen })} to={to}>
                <MenuItem
                  isOpen={isOpen}
                  title={name}
                  description={description}
                  icon={() => <Icon fontSize="2rem" color="danger" />}
                  onClick={toggleSidebarShow}
                />
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
}

export default SideBar;
