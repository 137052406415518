/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import bgSplash from './app/assets/img/bg-splash.png';
import ErrorBoundary from './app/components/ErrorBoundary';
import ManagerProvider from './app/contexts/manager';
import useAuth from './app/hooks/useAuth';
import AppRoutes from './app/routes/app.routes';
import AuthRoutes from './app/routes/auth.routes';
import api from './app/services/api';

import 'react-loading-skeleton/dist/skeleton.css';
import 'react-pro-sidebar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './app/assets/vendor/fontawesome-free-5.15.2-web/css/all.min.css';
import './app/assets/vendor/fontawesome-free-6.2.0-web/css/all.min.css';
import './app/assets/css/bootstrap.css';
import './app/assets/css/bootstrap9.css';
import './app/assets/css/custom.css';

const loading = (
  <div
    className="bg-primary opacity-7 d-flex w-100 vh-100 align-items-center justify-content-center position-fixed left-0 top-0"
    style={{ zIndex: 999999 }}
  >
    <Spinner type="grow" />
  </div>
);

const firebaseConfig = {
  apiKey: "AIzaSyAo0Dy_O-j_iitMbR1IMH3-CiRryVm2wag",
  authDomain: "nic-project-faaf3.firebaseapp.com",
  projectId: "nic-project-faaf3",
  storageBucket: "nic-project-faaf3.firebasestorage.app",
  messagingSenderId: "1005056751132",
  appId: "1:1005056751132:web:14d18c70f1f2e7849d91d9",
  measurementId: "G-NLQLWW8SVS"
};

// Initialize Firebase with error handling
let firebaseApp;
let messagingInstance;
try {
  firebaseApp = initializeApp(firebaseConfig);
  messagingInstance = getMessaging(firebaseApp);
} catch (error) {
  console.error('Erro ao inicializar Firebase:', error);
}

// Handle incoming messages with error handling
const onMessageListener = () => new Promise((resolve, reject) => {
  if (!messagingInstance) {
    reject(new Error('Firebase Messaging não está inicializado'));
    return;
  }

  try {
    onMessage(messagingInstance, (payload) => {
      resolve(payload);
    });
  } catch (error) {
    reject(error);
  }
});

function App() {
  const { isAuthenticated } = useAuth();

  // Service Worker registration with better error handling
  React.useEffect(() => {
    const registerServiceWorker = async () => {
      try {
        if (!('serviceWorker' in navigator)) {
          console.warn('Service Worker não é suportado neste navegador');
          return;
        }

        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Service Worker registrado com sucesso:', registration);
      } catch (err) {
        console.error('Erro ao registrar Service Worker:', err);
      }
    };

    registerServiceWorker();
  }, []);

  // Notification and Firebase token handling
  React.useEffect(() => {
    const setupNotifications = async () => {
      if (!isAuthenticated) return;

      try {
        // Verificar suporte a notificações
        if (!('Notification' in window)) {
          console.warn('Este navegador não suporta notificações desktop');
          return;
        }

        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
          console.log('Permissão para notificações não concedida');
          return;
        }

        if (!('serviceWorker' in navigator)) {
          console.warn('Service Worker não é suportado');
          return;
        }

        if (!messagingInstance) {
          console.warn('Firebase Messaging não está disponível');
          return;
        }

        const registration = await navigator.serviceWorker.ready;

        const currentToken = await getToken(messagingInstance, {
          vapidKey: 'BPT4QngDUEa93Ts2lWYz1QxQJfUORXch3Y8qnK8I7XTui94MnEDy_CHRvkCA9bhVG-mooN2v1piiPFaGIA64sVw',
          serviceWorkerRegistration: registration
        });

        if (currentToken) {
          console.log('Token:', currentToken);

          try {
            await api.put('/profissionais/firebaseToken', {
              firebaseToken: currentToken
            });
          } catch (err) {
            console.error('Erro ao atualizar firebase token:', err);
          }

          console.log('Iniciando onMessageListener');
          try {
            const payload = await onMessageListener();
            console.log('onMessageListener Notification', payload);
          } catch (err) {
            console.error('Erro no listener de mensagens:', err);
          }
        } else {
          console.warn('Não foi possível obter o token do Firebase');
        }
      } catch (err) {
        console.error('Erro ao configurar notificações:', err);
      }
    };

    setupNotifications();
  }, [isAuthenticated]);

  return (
    <Router>
      <Suspense fallback={loading}>
        <div className="mh-100 position-relative">
          <div
            className="bg-splash"
            style={{ backgroundImage: `url(${bgSplash})` }}
          >
            {isAuthenticated ? (
              <ManagerProvider>
                <AppRoutes />
              </ManagerProvider>
            ) : (
              <AuthRoutes />
            )}
          </div>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
