import React, { useState, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  InputGroupText,
  InputGroup,
} from 'reactstrap';

import * as Yup from 'yup';

import Divider from '../../components/Divider';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Notification from '../../components/Notification';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function RecoverPassword() {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const navigation = useNavigate();
  const { token } = useParams();

  const handleSubmit = () => {
    (async () => {
      try {
        setLoading(true);
        const data = {
          password,
          passwordConfirmation,
        };

        const schema = Yup.object().shape({
          password: Yup.string().required('Informe a senha'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas precisam ser iguais',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        await api.put(`/recoverPassword/${token}`, data);

        notificationRef.current.notify({
          message: 'Senha alterada com sucesso',
        });

        setLoading(false);
        setTimeout(() => {
          navigation('/');
        }, 2000);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Precisa informar todos os campos para alterar a senha',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Falha ao tentar alterar a senha',
          color: 'danger',
        });
      }
    })();
  };

  return (
    <div className="overflow-hidden">
      <Notification ref={notificationRef} />
      <Header />
      <Row className="mb-5" />
      <Row className="text-white text-center mb-2">
        <Col>
          <h6>Cadastrar nova senha</h6>
          <Divider />
        </Col>
      </Row>
      <Row className="justify-content-center p-3">
        <Col xs="12" sm="8" md="6" lg="4">
          <Card className="p-0 p-md-3" color="secondary">
            <CardBody>
              <Form>
                <FormGroup>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.password ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      type={passwordType}
                      placeholder="Senha"
                      value={password}
                      invalid={!!validationErrors.password}
                      onChange={({ target: { value } }) => setPassword(value)}
                    />
                    <InputGroupText onClick={() => setPasswordType((prevState) => (prevState === 'password' ? 'text' : 'password'))}>
                      <i
                        className={`fas ${
                          passwordType === 'password'
                            ? 'fa-eye-slash'
                            : 'fa-eye'
                        }`}
                      />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.password}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.passwordConfirmation ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      type={passwordType}
                      placeholder="Confirme a Senha"
                      value={passwordConfirmation}
                      invalid={!!validationErrors.passwordConfirmation}
                      onChange={({ target: { value } }) => setPasswordConfirmation(value)}
                    />
                    <InputGroupText onClick={() => setPasswordType((prevState) => (prevState === 'password' ? 'text' : 'password'))}>
                      <i
                        className={`fas ${
                          passwordType === 'password'
                            ? 'fa-eye-slash'
                            : 'fa-eye'
                        }`}
                      />
                    </InputGroupText>
                    <FormFeedback>
                      {validationErrors.passwordConfirmation}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                <Row>
                  <Col>
                    <Button color="warning" onClick={handleSubmit} block>
                      Alterar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default RecoverPassword;
