/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useCallback, forwardRef } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import {
  Button,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import { addDays, addMonths, format, getDate, getDay, getDaysInMonth, getMonth, getYear, subDays, subMonths } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
// import { range } from 'lodash';

import './styles.css';

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

const CustomDateTimeInput = forwardRef(
  ({ value, onClick, className, label }, ref) => (
    <Button className={className} onClick={onClick} ref={ref} block>
      {value ? `${label} - ${value}` : label || 'Selecione uma data e hora'}
    </Button>
  ),
);

function CustomTimeInput({ value, onChange, includeTimes }) {
  return (
    <Input
      id="dataHoraInicioSelect"
      type="select"
      value={value}
      onChange={onChange}
    >
      <option value="">Selecione uma Hora</option>
      {includeTimes.map((time, index) => (
        <option key={`${time}-${index}`} value={format(time, 'HH:mm')}>
          {format(time, 'HH:mm')}
        </option>
      ))}
    </Input>
  );
}

function DateTimePickerInput({
  selected,
  startDate,
  endDate,
  title = 'Data',
  label,
  placeholderText = 'dd/mm/aaaa',
  dateFormat = 'dd/MM/yyyy',
  locale = 'ptBR',
  // yearsRange = range(2000, getYear(new Date()) + 100, 1),
  // loading = false,
  invalidMessage,
  events = [],
  includeTimes = [],
  onChange,
  onMonthChange = () => null,
  onYearChange = () => null,
  onDaysOfCalendarChange = () => null,
  showTimeInput = true,
  ...props
}, ref) {
  /* const months = useMemo(() => [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]); */

  const daysOfCalendar = useCallback((date) => {
    const daysInMonth = getDaysInMonth(date);
    const daysOfMonthArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysOfMonthArray.push(new Date(getYear(date), getMonth(date), i));
    }
    const lastDaysOfPrevMonth = [];
    for (let i = getDay(daysOfMonthArray[0]); i >= 1; i--) {
      lastDaysOfPrevMonth.push(subDays(daysOfMonthArray[0], i));
    }
    const firstDaysOfNextMonth = [];
    for (
      let i = 1;
      i <= 6 - getDay(daysOfMonthArray[daysOfMonthArray.length - 1]);
      i++
    ) {
      firstDaysOfNextMonth.push(
        addDays(daysOfMonthArray[daysOfMonthArray.length - 1], i),
      );
    }

    onDaysOfCalendarChange([
      ...lastDaysOfPrevMonth,
      ...daysOfMonthArray,
      ...firstDaysOfNextMonth,
    ]);
  }, []);

  useEffect(() => {
    daysOfCalendar(new Date());
  }, []);

  const handleDecreaseMonth = useCallback((decreaseMonth, date) => {
    decreaseMonth();
    daysOfCalendar(subMonths(date, 1));
  }, []);

  const handleIncreaseMonth = useCallback((increaseMonth, date) => {
    increaseMonth();
    daysOfCalendar(addMonths(date, 1));
  }, []);

  const handleYear = useCallback((date) => {
    if (onYearChange) {
      onYearChange(date);
    }
    daysOfCalendar(date);
  }, []);

  const handleMonth = useCallback((date) => {
    if (onMonthChange) {
      onMonthChange(date);
    }
    daysOfCalendar(date);
  }, []);

  const renderCustomHeader = ({
    date,
    // changeYear,
    // changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <Button
        color="link"
        onClick={() => handleDecreaseMonth(decreaseMonth, date)}
        disabled={prevMonthButtonDisabled}
      >
        <i className="fas fa-arrow-circle-left" />
      </Button>
      {/* <Input
        className="w-100 mr-1"
        type="select"
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Input>
      <Input
        className="w-100 ml-1"
        type="select"
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {yearsRange.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Input> */}
      <h6 className="d-flex justify-content-between align-items-center">
        {format(date, 'MMMM yyyy', { locale: ptBR })}
      </h6>
      <Button
        color="link"
        onClick={() => handleIncreaseMonth(increaseMonth, date)}
        disabled={nextMonthButtonDisabled}
      >
        <i className="fas fa-arrow-circle-right" />
      </Button>
    </div>
  );

  const renderDayContents = (day, date) => {
    const eventsFilter = events.filter((e) => getDate(new Date(e.startDate)) === getDate(date)
      && getMonth(new Date(e.startDate)) === getMonth(date)
      && getYear(new Date(e.startDate)) === getYear(date));
    if (eventsFilter.length > 0) {
      return (
        <div className="position-relative">
          {getDate(date)}
          <div className="d-flex position-absolute top-0 left-1">
            {eventsFilter.map((event) => (
              <div key={event._id}>
                <UncontrolledTooltip target={`calendar-event-${event._id}`}>
                  {`${format(
                    new Date(event.startDate),
                    'HH:mm',
                    { locale: ptBR },
                  )} - ${format(new Date(event.endDate), 'HH:mm', {
                    locale: ptBR,
                  })}`}
                </UncontrolledTooltip>
                <div
                  id={`calendar-event-${event._id}`}
                  className="bg-success"
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      );
    }

    return getDate(date);
  };

  return (
    <div className="mb-3">
      <DatePicker
        ref={ref}
        renderCustomHeader={renderCustomHeader}
        renderDayContents={renderDayContents}
        title={title}
        placeholderText={placeholderText}
        dateFormat={dateFormat}
        locale={locale}
        selected={selected}
        startDate={startDate}
        endDate={endDate}
        onChange={(date) => onChange(date)}
        onMonthChange={handleMonth}
        onYearChange={handleYear}
        customInput={<CustomDateTimeInput label={label} />}
        showTimeInput={showTimeInput}
        timeIntervals={5}
        timeCaption="Hora"
        customTimeInput={<CustomTimeInput includeTimes={includeTimes} />}
        {...props}
      />
      {invalidMessage && (
        <span className="text-danger invalid-feedback d-inline">
          {invalidMessage}
        </span>
      )}
    </div>
  );
}

export default forwardRef(DateTimePickerInput);
