import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from 'react';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';

import AlertModal from '../../components/AlertModal';
import { AppContext } from '../../contexts/app';
import useApp from '../../hooks/useApp';
import useAuth from '../../hooks/useAuth';
import api from '../../services/api';

function CoresConfig() {
  const alertRef = useRef();
  const { loggedUser } = useAuth();
  const { setLoading, notificationRef } = useApp();
  const [especialidade, setEspecialidade] = useState('');
  const [especialidades, setEspecialidades] = useState([]);
  const [filteredEspecialidades, setFilteredEspecialidades] = useState([]);
  const [especialidadesCores, setEspecialidadesCores] = useState([]);
  const { setHeaderTitle } = useContext(AppContext);

  useLayoutEffect(() => {
    setHeaderTitle('Configurações de Cores');
  }, [setHeaderTitle]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('/getEspecialistas');
        setEspecialidades(data?.especialidades?.map((e) => ({
          value: e._id,
          label: e.nome,
          cor: e.cor,
        })) || []);
        setFilteredEspecialidades(data?.especialidades?.filter((e) => e.value === data?.especialidadesCores?.find((e1) => e1.especialidadeId === e._id)?.especialidadeId)?.map((e) => ({
          value: e._id,
          label: e.nome,
          cor: e.cor,
        })) || []);
        setEspecialidadesCores(data?.especialidadesCores?.map((e) => ({
          _id: e._id,
          especialidadeId: e.especialidadeId,
          label: data?.especialidades?.find((e1) => e1._id === e.especialidadeId)?.nome,
          cor: e.cor,
        })) || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleDelete = async (e) => {
    try {
      setLoading(true);
      const query = new URLSearchParams({
        especialidadesCores: JSON.stringify(especialidadesCores.filter((e1) => e1.especialidadeId !== e.especialidadeId && e1.cor)),
      });
      await api.delete(`/profissionais/${loggedUser._id}/especialidadesCores?${query.toString()}`);
      setEspecialidadesCores(especialidadesCores.filter((e1) => e1.especialidadeId !== e.especialidadeId));
      setFilteredEspecialidades((prevStates) => [...prevStates, { value: e.especialidadeId, label: e.label, cor: e.cor }].sort((a, b) => a.label.localeCompare(b.label)));
      alertRef.current.close();
      setLoading(false);
    } catch (error) {
      if (error && error.response && error.response.data) {
        const { message } = error.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // endpoint para atualizar as cores das especialidades /profissionais/:id/especialidadesCores
  const onSubmit = async () => {
    try {
      const especialidadesCoresData = especialidadesCores.map((e) => ({
        ...e,
        cor: e.cor || '#000000',
      }));
      console.log(especialidadesCoresData);
      setLoading(true);
      const { data } = await api.put(`/profissionais/${loggedUser._id}/especialidadesCores`, {
        especialidadesCores: especialidadesCoresData,
      });
      setEspecialidadesCores(data?.especialidadesCores?.map((e) => ({
        _id: e._id,
        especialidadeId: e.especialidadeId,
        label: especialidades.find((e1) => e1.value === e.especialidadeId)?.label,
        cor: e.cor,
      })) || []);
      notificationRef.current.notify({
        message: 'Cores atualizadas com sucesso',
        color: 'success',
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid>
      <AlertModal ref={alertRef} />
      <Row>
        <Col>
          <FormGroup>
            <Input
              id="especialidadeSelect"
              type="select"
              name="especialidade"
              value={especialidade}
              onChange={({ target: { value } }) => {
                setEspecialidade(value);
                setEspecialidadesCores((prevStates) => {
                  const newState = [...prevStates, { especialidadeId: value, label: especialidades.find((e1) => e1.value === value)?.label, cor: '' }];
                  setFilteredEspecialidades(especialidades.filter((e) => !newState.some((e1) => e1.especialidadeId === e.value)));
                  return newState;
                });
              }}
            >
              <option value="">Selecione um Tipo de Atendimento</option>
              {filteredEspecialidades.map((e) => (
                <option key={e.value} value={e.value}>
                  {e.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex flex-column">
            {especialidadesCores.map((e) => (
              <div key={e.especialidadeId} className="d-flex flex-column bg-light p-4 rounded mb-3">
                <span className="font-weight-600">{e.label}</span>
                <Form>
                  <FormGroup>
                    <Input
                      type="color"
                      placeholder="Cor"
                      value={e.cor}
                      onChange={({ target: { value } }) => setEspecialidadesCores(especialidadesCores.map((e1) => (e1.especialidadeId === e.especialidadeId ? { ...e1, cor: value } : e1)))}
                    />
                  </FormGroup>
                </Form>
                { e._id && (
                  <div className="d-flex justify-content-end">
                    <Button
                      color="danger"
                      onClick={() => {
                        alertRef.current.show({
                          title: 'Remover Cor',
                          message: 'Tem certeza que deseja remover a cor deste tipo de atendimento?',
                          onConfirm: () => handleDelete(e),
                        });
                      }}
                    >
                      Excluir
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-end mt-5">
            <Button color="primary" onClick={onSubmit}>Salvar</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CoresConfig;
