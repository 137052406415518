import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Container,
  CardHeader,
} from 'reactstrap';

import * as Yup from 'yup';

import backgroundnuvens from '../../assets/img/background-nuvens2.png';
import h12 from '../../assets/img/h1b.png';
import logo2 from '../../assets/img/logocompleto.png';
import Footer from '../../components/Footer';
import Notification from '../../components/Notification';
import { useAuth } from '../../hooks/useAuth';
import api from '../../services/api';

function Login() {
  const { signIn } = useAuth();
  const notificationRef = useRef();
  const [validationErrors, setValidationErrors] = useState({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = () => {
    (async () => {
      try {
        const data = {
          email,
          password,
        };

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email'),
          password: Yup.string().required('Informe uma senha'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        const res = await api.post('/loginEspecialistas', data);
        const { token, user } = res.data;

        notificationRef.current.notify({
          message: 'Login realizado com sucesso',
        });

        setTimeout(() => {
          signIn(token, user);
          navigate('/');
        }, 2000);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Precisa informar o login para ter acesso a sua conta',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar Login',
          color: 'danger',
        });
      }
    })();
  };

  return (
    <Container fluid>
      <Notification ref={notificationRef} />
      <Row className="justify-content-between px-2 py-3 p-sm-5">
        <Col style={{ zIndex: 2 }}>
          <div className="d-flex justify-content-center">
            <img
              src={logo2}
              alt=""
              style={{
                width: '100%',
                maxWidth: '200px',
              }}
            />
          </div>
        </Col>
        <Col xs="12" sm="8" md="6" style={{ zIndex: 2 }}>
          <Card className="p-3 p-md-5" style={{ backgroundColor: 'transparent' }}>
            <CardHeader style={{ backgroundColor: 'transparent' }}>
              <h2 className="font-weight-800 text-center">Login de Especialistas</h2>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    invalid={!!validationErrors.email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <FormFeedback>{validationErrors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    invalid={!!validationErrors.password}
                    onChange={({ target: { value } }) => setPassword(value)}
                  />
                  <FormFeedback>{validationErrors.password}</FormFeedback>
                </FormGroup>
                <Row>
                  <Col>
                    <Button color="secondary" onClick={handleSubmit} block>
                      Logar
                    </Button>
                  </Col>
                </Row>
                {/* <Row className="pt-3">
                  <Button
                    color="link"
                    onClick={() => navigate('/esquecisenha')}
                    block
                    className="border-white"
                  >
                    Perdeu sua senha?
                  </Button>
                </Row> */}
                <Row>
                  <Col>
                    <Button
                      className="my-2 text-gray"
                      color="link"
                      onClick={() => navigate('/cadastro')}
                      block
                    >
                      Não tem acesso? Solicite agora.
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${backgroundnuvens})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Col className="p-0" style={{ zIndex: 2 }}>
          <div className="d-flex justify-content-center align-items-baseline">
            <img
              src={h12}
              alt="h12"
              style={{
                width: '100%',
                maxWidth: '600px',
              }}
            />
          </div>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}

export default Login;
