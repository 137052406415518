/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';

import classNames from 'classnames';

function MenuItem({ title, description, icon: Icon, isOpen, onClick }) {
  return (
    <div className="menu-item position-relative px-3 w-100">
      {Icon && (
        <div className={classNames('menu-item-icon bg-secondary shadow p-2', { 'is-closed': !isOpen })}>
          <Icon />
        </div>
      )}
      <Card
        className={classNames('cursor-pointer shadow', { 'is-closed': !isOpen })}
        color="light"
        onClick={onClick}
      >
        <CardBody>
          <h5 className="font-weight-700 ml-3">{title}</h5>
          <small className="text-dark ml-3">{description}</small>
        </CardBody>
      </Card>
      <Button
        className={classNames('menu-item-goto-button', { 'is-closed': !isOpen })}
        onClick={onClick}
      >
        <i className="fa-solid fa-circle-chevron-right text-danger" />
      </Button>
    </div>
  );
}

export default MenuItem;
