import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import ErrorBoundary from './app/components/ErrorBoundary';
import AppProvider from './app/contexts/app';
import AuthProvider from './app/contexts/auth';
import NotificationsProvider from './app/contexts/notifications';
import SocketProvider from './app/contexts/socket-provider';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ErrorBoundary>
    <AppProvider>
      <AuthProvider>
        <SocketProvider>
          <NotificationsProvider>
            <App />
          </NotificationsProvider>
        </SocketProvider>
      </AuthProvider>
    </AppProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

serviceWorker.register();
