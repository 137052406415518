import React, { useState, useEffect, useCallback } from 'react';

import { SIDEBARSHOW, SIDEBAROPEN } from '../../constants/app';
import Content from './content';
import SideBar from './sidebar';
import SideBarBackdrop from './sidebar/BackDrop';

import './styles.css';

function LayoutAdmin() {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [sidebarIsShow, setSidebarShow] = useState(true);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prevStateOpen) => {
      if (sidebarIsShow === false) {
        localStorage.setItem(SIDEBARSHOW, 'show');
        setSidebarShow(true);
        return prevStateOpen;
      }

      localStorage.setItem(SIDEBAROPEN, prevStateOpen ? 'closed' : 'opened');
      return !prevStateOpen;
    });
  }, [sidebarIsShow]);

  const toggleSidebarShow = useCallback(() => {
    setSidebarShow((prevState) => {
      localStorage.setItem(SIDEBARSHOW, prevState ? 'noshow' : 'show');
      return !prevState;
    });
  }, []);

  useEffect(() => {
    const sidebarShowStorage = localStorage.getItem(SIDEBARSHOW);
    if (sidebarShowStorage) {
      setSidebarShow(sidebarShowStorage === 'show');
    }

    const sidebarOpenStorage = localStorage.getItem(SIDEBAROPEN);
    if (sidebarOpenStorage) {
      setSidebarOpen(sidebarOpenStorage === 'opened');
    }
  }, []);

  return (
    <div className="admin-container wrapper">
      <SideBar
        toggleSidebarOpen={toggleSidebar}
        toggleSidebarShow={toggleSidebarShow}
        isOpen={sidebarIsOpen}
        isShow={sidebarIsShow}
      />
      <SideBarBackdrop toggle={toggleSidebarShow} isShow={sidebarIsShow} isOpen={sidebarIsOpen} />
      <Content toggleSidebar={toggleSidebar} sidebarIsShow={sidebarIsShow} sidebarIsOpen={sidebarIsOpen} />
    </div>
  );
}

export default LayoutAdmin;
