/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useContext, useLayoutEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Label,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { addHours, format, getDate, getHours, getMinutes, getMonth, getYear, parseISO, startOfDay, endOfDay } from 'date-fns';

import CustomSelectSearch from '../../components/CustomSelectSearch';
import DateTimePickerInput from '../../components/DateTimePickerInput';
import { AppContext } from '../../contexts/app';
import useApp from '../../hooks/useApp';
import useAuth from '../../hooks/useAuth';
import useGenerateIntervalHours from '../../hooks/useGenerateIntervalHours';
import api from '../../services/api';
import { schema } from './validationSchema';

function Lembrete() {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setLoading, notificationRef } = useApp();
  const { loggedUser } = useAuth();
  const { generateIntervalHours } = useGenerateIntervalHours();
  const [includeTimes, setIncludeTimes] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [loadingParticipantes, setLoadingParticipantes] = useState(false);
  const { control, watch, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      nome: '',
      cor: '#0082c8',
      repetir: 'padrao',
      data: '',
      dataHoraInicio: '',
      dataHoraTermino: '',
      dataRepetirTermino: '',
      participantes: [],
      observacoes: '',
    },
    resolver: yupResolver(schema)
  });
  const watchData = watch('data');
  const watchDataHoraInicio = watch('dataHoraInicio');
  const watchDataHoraTermino = watch('dataHoraTermino');
  const watchRepetir = watch('repetir');
  const { setHeaderTitle } = useContext(AppContext);

  useLayoutEffect(() => {
    setHeaderTitle('Lembrete');
  }, [setHeaderTitle]);

  useEffect(() => {
    if (!params?.id) {
      setValue('dataHoraInicio', '');
      setValue('dataHoraTermino', '');
    }
  }, [params?.id]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingParticipantes(true);
        const { data = [] } = await api.get(`/profissionalParticipantes`);
        setParticipantes(data.map((d) => ({
          value: d.id,
          label: `${d.name} (${d.type})`,
          type: d.type,
        })));
        setLoadingParticipantes(false);
      } catch (error) {
        setLoadingParticipantes(false);
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    setIncludeTimes([]);
    console.log("🚀 ~ useEffect ~ watchData:", watchData);
    if (watchData) {
      const startOfDayDate = startOfDay(watchData);
      console.log("🚀 ~ useEffect ~ startOfDayDate:", startOfDayDate);
      const endOfDayDate = endOfDay(watchData);
      console.log("🚀 ~ useEffect ~ endOfDayDate:", endOfDayDate);
      const availabilitiesTimes = generateIntervalHours(startOfDayDate, getHours(startOfDayDate), getHours(endOfDayDate), getMinutes(startOfDayDate), getMinutes(endOfDayDate));
      setIncludeTimes(availabilitiesTimes);
      console.log("🚀 ~ useEffect ~ availabilitiesTimes:", availabilitiesTimes);
    }
  }, [watchData]);

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ watchData:', watchData);
    console.log('🚀 ~ useEffect ~ watchDataHoraInicio:', watchDataHoraInicio);
    try {
      if (watchDataHoraInicio && watchData && !watchDataHoraTermino) {
        const dataHoraInicioAddedOneHour = typeof watchDataHoraInicio === 'string'
          ? addHours(new Date(getYear(watchData), getMonth(watchData), getDate(watchData), Number(`${watchDataHoraInicio}`.split(':')[0]), Number(`${watchDataHoraInicio}`.split(':')[1])), 1)
          : addHours(watchDataHoraInicio, 1);
        console.log('🚀 ~ useEffect ~ dataHoraInicioAddedOneHour:', dataHoraInicioAddedOneHour);
        setValue('dataHoraTermino', format(dataHoraInicioAddedOneHour, 'HH:mm'));
      }
    } catch (error) {
      console.log('🚀 ~ useEffect ~ error:', error);
    }
  }, [watchDataHoraInicio, watchData, watchDataHoraTermino]);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ params?.data:", searchParams.get('data'));
    if (searchParams.get('data')) {
      setValue('data', new Date(searchParams.get('data')));
    }
    if (params?.id) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await api.get(`/profissionalEventos/${params.id}`);
          console.log('🚀 ~ file: index.js:150 ~ data:', data);
          setValue('nome', data?.nome || '');
          setValue('cor', data?.cor || '');
          setValue('data', data?.dataHoraInicio ? parseISO(data.dataHoraInicio) : null);
          setValue('dataHoraInicio', data?.dataHoraInicio ? format(parseISO(data.dataHoraInicio), 'HH:mm') : '');
          setValue('dataHoraTermino', data?.dataHoraTermino ? format(parseISO(data.dataHoraTermino), 'HH:mm') : '');
          setValue('dataRepetirTermino', data?.dataRepetirTermino ? format(parseISO(data.dataRepetirTermino), 'yyyy-MM-dd') : '');
          setValue('repetir', data?.repetir || 'padrao');
          setValue('participantes', data?.participantes?.map((p) => p.id) || []);
          setValue('observacoes', data?.observacoes || '');
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      })();
    }
  }, [params]);

  const onSubmit = (formValues) => {
    console.log(formValues);
    (async () => {
      try {
        setLoading(true);
        const data = {
          profissional: loggedUser._id,
          nome: formValues.nome,
          cor: formValues.cor,
          data: watchData,
          dataHoraInicio: new Date(getYear(watchData), getMonth(watchData), getDate(watchData), Number(formValues.dataHoraInicio.split(':')[0]), Number(formValues.dataHoraInicio.split(':')[1])),
          dataHoraTermino: new Date(getYear(watchData), getMonth(watchData), getDate(watchData), Number(formValues.dataHoraTermino.split(':')[0]), Number(formValues.dataHoraTermino.split(':')[1])),
          dataRepetirTermino: formValues.dataRepetirTermino,
          repetir: formValues.repetir,
          participantes: participantes.filter((p) => formValues.participantes?.includes(p.value)).map((p) => ({
            id: p.value,
            name: p.label,
            type: p.type,
          })),
          observacoes: formValues.observacoes,
        };
        console.log('🚀 ~ data:', data);

        if (params?.id) {
          await api.put(`/profissionalEventos/${params.id}`, data);

          /* setTimeout(() => {
            navigate('/');
          }, 1000); */
          setTimeout(() => {
            setLoading(false);
            notificationRef.current.notify({
              message: 'Lembrete atualizado com sucesso',
            });
            navigate(`/agenda`);
          }, 1000);
          return;
        }

        const { data: newEvento } = await api.post('/profissionalEventos', data);

        /* setTimeout(() => {
          navigate(`/lembrete/${newEvento._id}`);
        }, 1000); */
        setTimeout(() => {
          setLoading(false);
          notificationRef.current.notify({
            message: 'Lembrete criado com sucesso',
          });
          navigate(`/agenda`);
        }, 1000);
      } catch (err) {
        console.log('🚀 ~ err:', err);
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar Lembrete',
          color: 'danger',
        });
      }
    })();
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Row className="mt-2">
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="nome">
                        Nome do Lembrete
                      </Label>
                      <Input
                        id="nome"
                        name={name}
                        value={value}
                        onChange={onChange}
                        invalid={!!errors?.nome?.message}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="cor"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="cor">
                        Cor de exibição do lembrete
                      </Label>
                      <Input
                        id="cor"
                        name={name}
                        type="color"
                        placeholder="Cor"
                        value={value}
                        onChange={({ target: { value: newValue } }) => onChange(newValue)}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="data"
                  control={control}
                  render={({ field }) => (
                    <DateTimePickerInput
                      label="Data do Lembrete"
                      invalidMessage={errors?.data?.message}
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      showTimeInput={false}
                    />
                  )}
                />
                <Row>
                  <Col xs={12} sm={6}>
                    <Controller
                      name="dataHoraInicio"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <FormGroup>
                          <Label for="dataHoraInicioSelect">
                            Hora de início do lembrete
                          </Label>
                          <Input
                            id="dataHoraInicioSelect"
                            type="select"
                            name={name}
                            value={value}
                            onChange={onChange}
                            invalid={!!errors?.dataHoraInicio?.message}
                          >
                            <option value="">Selecione uma Hora</option>
                            {includeTimes.map((time, index) => (
                              <option key={`${time}-${index}`} value={format(time, 'HH:mm')}>
                                {format(time, 'HH:mm')}
                              </option>
                            ))}
                          </Input>
                          <FormFeedback>{errors?.dataHoraInicio?.message}</FormFeedback>
                        </FormGroup>
                      )}
                    />
                  </Col>
                  {/* <TimePickerMaskedInput
                    label="Hora de término do atendimento"
                    date={watchDataAtendimento}
                    invalidMessage={errors?.dataHoraTermino?.message}
                    onChange={(e) => field.onChange(e)}
                    selected={field.value}
                    includeTimes={includeTimes}
                  /> */}
                  <Col xs={12} sm={6}>
                    <Controller
                      name="dataHoraTermino"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <FormGroup>
                          <Label for="dataHoraTerminoSelect">
                            Hora de término do lembrete
                          </Label>
                          <Input
                            id="dataHoraTerminoSelect"
                            type="select"
                            name={name}
                            value={value}
                            onChange={onChange}
                            invalid={!!errors?.dataHoraTermino?.message}
                          >
                            <option value="">Selecione uma Hora</option>
                            {includeTimes.map((time, index) => (
                              <option key={`${time}-${index}`} value={format(time, 'HH:mm')}>
                                {format(time, 'HH:mm')}
                              </option>
                            ))}
                          </Input>
                          <FormFeedback>{errors?.dataHoraTermino?.message}</FormFeedback>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                <Controller
                  name="repetir"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup tag="fieldset">
                      <Label>
                        Repetir Lembrete
                      </Label>
                      <FormGroup check>
                        <Input
                          name={name}
                          type="radio"
                          value="padrao"
                          checked={value === 'padrao'}
                          onChange={onChange}
                        />
                        {' '}
                        <Label check>
                          Não Repetir
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name={name}
                          type="radio"
                          value="diario"
                          checked={value === 'diario'}
                          onChange={onChange}
                        />
                        {' '}
                        <Label check>
                          Diariamente
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name={name}
                          type="radio"
                          value="semanal"
                          checked={value === 'semanal'}
                          onChange={onChange}
                        />
                        {' '}
                        <Label check>
                          Semanalmente
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name={name}
                          type="radio"
                          value="mensal"
                          checked={value === 'mensal'}
                          onChange={onChange}
                        />
                        {' '}
                        <Label check>
                          Mensalmente
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  )}
                />
                {watchRepetir !== 'padrao' && (
                  <Controller
                    name="dataRepetirTermino"
                    control={control}
                    render={({ field: { onChange, name, value } }) => (
                      <DateTimePickerInput
                        label="Data de término da repetição"
                        invalidMessage={errors?.dataRepetirTermino?.message}
                        onChange={(e) => onChange(e)}
                        selected={value}
                        showTimeInput={false}
                      />
                    )}
                  />
                )}
                <Controller
                  name="participantes"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="participantesSelect">
                        Convidar Participantes (Campo não obrigatório e a notificação no sistema não substitui o convite formal. Envie também por e-mail ou WhatsApp)
                      </Label>
                      <CustomSelectSearch
                        id="participantesSelect"
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={participantes}
                        placeholder="Selecione um ou mais Participantes"
                        invalid={!!errors?.participantes?.message}
                        errorMessage={errors?.participantes?.message}
                        isLoading={loadingParticipantes}
                        loadingMessage="Carregando participantes"
                        noOptionsMessage="Nenhum participante encontrado"
                        isMultiple
                        disabled={params?.id}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="observacoes"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormGroup>
                      <Label for="observacoes">
                        Observações
                      </Label>
                      <Input
                        id="observacoes"
                        type="textarea"
                        name={name}
                        value={value}
                        onChange={onChange}
                        invalid={!!errors?.observacoes?.message}
                      />
                      <FormFeedback>{errors?.observacoes?.message}</FormFeedback>
                    </FormGroup>
                  )}
                />
                <Button type="submit" className="my-4" color="success" block>
                  {params?.id ? 'Alterar Lembrete' : 'Confirmar Lembrete'}
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Lembrete;
