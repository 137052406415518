/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import axios from 'axios';

import { handleAsyncError } from '../components/ErrorBoundary';
import { TOKEN_KEY, ID_KEY } from '../constants/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Variável para armazenar a referência do ErrorBoundary
let errorBoundaryInstance = null;

// Função para configurar a instância do ErrorBoundary
export const setErrorBoundaryInstance = (instance) => {
  errorBoundaryInstance = instance;
};

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const id = localStorage.getItem(ID_KEY);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.id = id;
  }
  return config;
});

// Interceptor de resposta
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Se tivermos uma instância do ErrorBoundary e o erro for de autenticação (401)
    // ou outro erro que você queira mostrar na página de erro
    if (errorBoundaryInstance && (error.response?.status === 401 || error.response?.status === 403)) {
      handleAsyncError(error, errorBoundaryInstance);
    }
    return Promise.reject(error);
  }
);

export default api;
