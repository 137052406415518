/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useMemo, useContext, useLayoutEffect } from 'react';
import {
  Col,
  Row,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import CustomTable from '../../components/CustomTable';
import FaturaCard from '../../components/FaturaCard';
import { MONTH, SITUACAO } from '../../constants/app';
import { AppContext } from '../../contexts/app';
import { numberToCurrency } from '../../utils/functions';
import useFaturas from './useFaturas';
import useFaturasDetails from './useFaturasDetails';

function Faturas() {
  const { faturas, loading } = useFaturas();
  const { faturasDetails, loading: loadingDetails, load, download, page, hasNextPage } = useFaturasDetails();
  const [showDetails, setShowDetails] = useState(false);
  const [title, setTitle] = useState('');
  const { setHeaderTitle } = useContext(AppContext);

  useLayoutEffect(() => {
    setHeaderTitle('Faturas');
  }, [setHeaderTitle]);

  const handleRowClick = useCallback((row) => {
    const { year, month, situacao } = row;
    load({
      year,
      month,
      situation: situacao,
      page: 0,
    });
    setTitle(`Detalhes - ${MONTH[row.month]} ${row.year}`);
    setShowDetails(true);
  }, [load]);

  const columns = useMemo(
    () => [
      {
        dataField: 'month-year',
        text: 'Mês/Ano',
        formatter: (row) => `${MONTH[row.month]} ${row.year}`,
      },
      {
        dataField: 'total',
        text: 'Total',
        formatter: (row) => `${numberToCurrency(row.total)} (${row.qty})`,
        width: '160px',
      },
      {
        dataField: 'situacao',
        text: 'Situação',
        formatter: (row) => `${SITUACAO[row.situacao]}`,
      },
    ],
    [],
  );

  const renderFooter = () => (
    <tfoot>
      <tr>
        <td style={{ borderRight: '2px solid #fff' }}>
          TOTAL
        </td>
        <td>
          {numberToCurrency(faturas?.filter?.((fatura) => fatura.situacao !== 'cancelado')?.reduce?.((acc, curr) => acc + curr.total, 0) || 0)}
          {' '}
          (
          {faturas?.filter?.((fatura) => fatura.situacao !== 'cancelado')?.reduce?.((acc, curr) => acc + curr.qty, 0) || 0}
          )
        </td>
      </tr>
    </tfoot>
  );

  return (
    <Container fluid style={{ paddingLeft: '10px', paddingRight: '10px' }}>
      <Row>
        <Col>
          <CustomTable
            columns={columns}
            data={faturas}
            loading={loading}
            paging={false}
            onRowClick={handleRowClick}
            footer={renderFooter}
          />
        </Col>
      </Row>
      <Modal
        isOpen={showDetails}
        size="lg"
        onClose={() => setShowDetails(false)}
        centered
      >
        <ModalHeader>
          <strong>{title}</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowDetails(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Button color="success" className="mb-4" onClick={download} block disabled={loadingDetails}>
            Download PDF
          </Button>
          {loadingDetails ? (
            <Row>
              <Col>
                {[...(!page ? [] : faturasDetails), ...Array.from({ length: 3 })].map((fatura, index) => (
                  <FaturaCard key={`${fatura?.tipo}-${fatura?.paciente}-${fatura?.data}-${index}`} fatura={fatura} loading={!fatura?.tipo} />
                ))}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                {faturasDetails.map((fatura, index) => (
                  <FaturaCard key={`${fatura?.tipo}-${fatura?.paciente}-${fatura?.data}-${index}`} fatura={fatura} loading={false} />
                ))}
              </Col>
            </Row>
          )}
          { hasNextPage && (
            <Row>
              <Col>
                <Button color="success" className="mb-4" block onClick={() => load({ page: page + 1 })}>
                  Carregar mais Faturas
                </Button>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default Faturas;
