import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, CardHeader, CardBody, Col, Row, Table } from 'reactstrap';

import { SITUACAO } from '../../constants/app';
import { numberToCurrency } from '../../utils/functions';

function FaturaCard({ fatura, loading, ...rest }) {
  const {
    agendamento,
    tipo,
    paciente,
    data,
    horaInicio,
    horaTermino,
    qtdeHoras,
    valor,
  } = fatura || {};

  if (loading) {
    return (
      <Card className="shadow" {...rest}>
        <CardBody>
          <Row>
            <Col>
              <Skeleton height={20} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Skeleton height={20} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Início</th>
                    <th>Término</th>
                    <th>Duração</th>
                    <th>valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><Skeleton height={20} width="100%" aria-label="Loading" /></td>
                    <td><Skeleton height={20} width="100%" aria-label="Loading" /></td>
                    <td><Skeleton height={20} width="100%" aria-label="Loading" /></td>
                    <td><Skeleton height={20} width="100%" aria-label="Loading" /></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="shadow" {...rest}>
      <CardHeader>
        <strong>{`Paciente: ${paciente}`}</strong>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            {`Tipo de Atendimento: ${tipo}`}
          </Col>
        </Row>
        <Row>
          <Col>
            {`Data: ${data}`}
          </Col>
        </Row>
        <Row>
          <Col>
            {`Situação: ${agendamento?.status === 'cancelado' ? 'Cancelado' : SITUACAO[fatura.situacao]}`}
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>Início</th>
                  <th>Término</th>
                  <th>Duração</th>
                  <th>valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{horaInicio}</td>
                  <td>{horaTermino}</td>
                  <td>{qtdeHoras}</td>
                  <td>{numberToCurrency(valor)}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default FaturaCard;
