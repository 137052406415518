import React from 'react';
import { Routes, Route } from 'react-router-dom';

import LayoutAdmin from '../layouts/user';
// import Agendamentos from '../pages/Agendamentos';
import Agenda from '../pages/Agenda';
import Agendamento from '../pages/Agendamento';
import CoresConfig from '../pages/CoresConfig';
import Lembrete from '../pages/Evento';
// import CalendarioAgendamentos from '../pages/CalendarioAgendamentos';
import Faturas from '../pages/Faturas';
// import Reembolsos from '../pages/Reembolsos';
import Home from '../pages/Home';
import Notifications from '../pages/Notifications';
import Profile from '../pages/Profile';

function AppUserRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LayoutAdmin />}>
        <Route path="/" element={<Home />} />
        {/* <Route path="/agendamentos" element={<Agendamentos />} /> */}
        <Route path="/agendamento" element={<Agendamento />} />
        <Route path="/agendamento/:id" element={<Agendamento />} />
        <Route path="/lembrete" element={<Lembrete />} />
        <Route path="/lembrete/:id" element={<Lembrete />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/cores-config" element={<CoresConfig />} />
        <Route path="/notificacoes" element={<Notifications />} />
        {/* <Route path="/calendario-agendamentos" element={<CalendarioAgendamentos />} /> */}
        <Route path="/faturas" element={<Faturas />} />
        {/* <Route path="/reembolsos" element={<Reembolsos />} /> */}
        <Route path="/perfil" element={<Profile />} />
      </Route>
    </Routes>
  );
}

export default AppUserRoutes;
