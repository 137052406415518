import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import backgroundnuvens from '../../assets/img/background-nuvens.png';
import logocompleto from '../../assets/img/logo.png';

function Footer() {
  return (
    <Row>
      <Col
        style={{
          backgroundImage: `url(${backgroundnuvens})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          zIndex: 2,
        }}
      >
        <Row>
          <Col>
            <div className="d-flex justify-content-center font-weight-800" />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex flex-wrap justify-content-around text-gray p-5">
              <div>
                <Row className="mb-1">
                  <Col>
                    <img
                      src={logocompleto}
                      alt="logo"
                      style={{ width: '100%', maxWidth: '200px' }}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="mb-1 mt-5 mt-md-0">
                  <Col>
                    <span className="font-weight-700">SÃO PAULO</span>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <i className="fas fa-map-marker-alt mr-2" />
                      <div>
                        Rua Roque Petrella, 220
                        <br />
                        04581-050 – Brooklin – SP
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center mb-1">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <i className="fas fa-map-marker-alt mr-2" />
                      São Paulo SP
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center mb-1">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <i className="fas fa-phone mr-2" />
                      <span>(11) 99779-1486</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="mb-1 mt-5 mt-md-0">
                  <Col>
                    <span className="font-weight-700">GOIÂNIA</span>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <i className="fas fa-map-marker-alt mr-2" />
                      <div>
                        Rua 3, Setor Oeste, 1022
                        <br />
                        edifício West Office
                        <br />
                        sala 508
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center mb-1">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <i className="fas fa-map-marker-alt mr-2" />
                      Goiânia GO
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center mb-1">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <i className="fas fa-phone mr-2" />
                      <span>(62) 8162-2110</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="mb-1 mt-5 mt-md-0">
                  <Col>
                    <span className="font-weight-700">EMAIL</span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <i className="fas fa-envelope mr-2" />
                      <a href="mailto:contato@abanic.com.br">
                        contato@abanic.com.br
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-1 mt-5 mt-md-0">
                  <Col>
                    <span className="font-weight-700">TRABALHE CONOSCO</span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <i className="fas fa-envelope mr-2" />
                      <a href="mailto:trabalheconosco@abanic.com.br">
                        trabalheconosco@abanic.com.br
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="mb-3 mt-5 mt-md-0">
                  <Col>
                    <span className="font-weight-700">REDES SOCIAIS</span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <div className="d-flex align-items-center ">
                      <a
                        className="text-decoration-none"
                        href="https://www.instagram.com/nic.aba/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="btn-social btn-instagram btn-toggle">
                          <i className="fab fa-instagram" />
                          <span className="ml-2">Acessar o Instagram</span>
                        </Button>
                      </a>
                      <a
                        className="text-decoration-none"
                        href="https://www.facebook.com/nucleointervencaocomportamental"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="btn-social btn-facebook btn-toggle">
                          <i className="fab fa-facebook" />
                          <span className="ml-2">Seguir o Facebook</span>
                        </Button>
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="bg-white">
          <Col>
            <div className="d-flex justify-content-center text-center p-3">
              <span>
                NIC – Núcleo de Intervenção Comportamental | &nbsp;
                {new Intl.DateTimeFormat('pt-BR', {
                  year: 'numeric',
                }).format(new Date())}
                © Todos os Direitos Reservados | &nbsp;
                <a
                  href="https://athoslabs.com.br/"
                  className="text-black"
                  target="_blank"
                  rel="noreferrer"
                >
                  By AthosLabs.
                </a>
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;
