/* eslint-disable import/no-cycle */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import React, { forwardRef } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardHeader,
} from 'reactstrap';

import PropTypes from 'prop-types';

import backgroundnuvens from '../assets/img/background-nuvens2.png';
import h12 from '../assets/img/h1b.png';
import logo2 from '../assets/img/logocompleto.png';
import { TOKEN_KEY, ID_KEY, USER_NAME } from '../constants/auth';
import { setErrorBoundaryInstance } from '../services/api';
import Footer from './Footer';

class ErrorBoundaryClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      eventId: null
    };

    // Bind dos métodos
    this.handleClearCacheAndReload = this.handleClearCacheAndReload.bind(this);
  }

  componentDidMount() {
    // Registra a instância no serviço de API
    setErrorBoundaryInstance(this);
  }

  componentWillUnmount() {
    // Limpa a referência quando o componente for desmontado
    setErrorBoundaryInstance(null);
  }

  static getDerivedStateFromError(error) {
    // Atualiza o estado para que a próxima renderização mostre a UI alternativa
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error, errorInfo) {
    // Você pode registrar o erro em um serviço de relatório de erros aqui
    this.setState({
      error,
      errorInfo
    });

    // Log do erro no console
    if (error) {
      console.group('🚨 Error Boundary Caught Error');
      console.error('Error:', error);
      console.error('Error Info:', errorInfo);
      console.groupEnd();
    }

    // Exemplo de como você poderia enviar para um serviço de monitoramento
    // this.logErrorToService(error, errorInfo);
  }

  async handleClearCacheAndReload() {
    // Clear localStorage items
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ID_KEY);
    localStorage.removeItem(USER_NAME);

    // Clear browser caches
    if ('caches' in window) {
      try {
        const keys = await caches.keys();
        await Promise.all(
          keys.map((key) => {
            if (key.includes('nic-app')) {
              return caches.delete(key);
            }
            return Promise.resolve();
          })
        );
        console.log('Cache cleared successfully');
      } catch (error) {
        console.error('Error clearing cache:', error);
      }
    }

    // Reload the page
    window.location.reload();
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { fallback, children } = this.props;

    // Se houver um fallback personalizado fornecido via props, use-o
    if (hasError && fallback) {
      return fallback(error, errorInfo);
    }

    // UI padrão de erro
    if (hasError) {
      return (
        (
          <Container fluid>
            <Row className="justify-content-between px-2 py-3 p-sm-5">
              <Col style={{ zIndex: 2 }}>
                <div className="d-flex justify-content-center">
                  <img
                    src={logo2}
                    alt=""
                    style={{
                      width: '100%',
                      maxWidth: '200px',
                    }}
                  />
                </div>
              </Col>
              <Col xs="12" style={{ zIndex: 2 }}>
                <Card className="p-3 p-md-5" style={{ backgroundColor: 'transparent' }}>
                  <CardHeader style={{ backgroundColor: 'transparent' }}>
                    <h2 className="font-weight-800 text-center">App de Especialistas</h2>
                  </CardHeader>
                  <CardBody>
                    <div className="text-center p-4">
                      <div className="error-icon mb-4">
                        <i className="fas fa-exclamation-circle text-danger" style={{ fontSize: '4rem' }} />
                      </div>

                      <h1 className="text-danger mb-4">
                        Ops, algo deu errado!
                      </h1>

                      <p className="text-muted mb-1">
                        Encontramos um erro que exige a reinicialização do aplicativo.
                      </p>
                      <p className="text-muted mb-4">
                        Clique no botão abaixo para reiniciar rapidamente e continuar usando normalmente.
                      </p>

                      <div className="d-flex justify-content-center gap-3">
                        <button
                          className="btn btn-primary mx-2 w-100 py-4"
                          onClick={this.handleClearCacheAndReload}
                        >
                          <i className="fas fa-sync-alt mr-2" />
                          {' '}
                          Reiniciar Aplicativo
                        </button>
                      </div>

                      {error && (
                        <div className="mt-5 text-left">
                          <details className="error-details">
                            <summary className="text-danger mb-2">Detalhes Técnicos do Erro</summary>
                            <div className="bg-light p-3 rounded border">
                              <h6 className="font-weight-bold">Mensagem do Erro:</h6>
                              <pre className="text-danger mb-3">
                                {error && error.toString()}
                              </pre>

                              {errorInfo && (
                                <>
                                  <h6 className="font-weight-bold">Stack Trace:</h6>
                                  <pre className="text-muted" style={{ fontSize: '0.8rem' }}>
                                    {errorInfo && errorInfo.componentStack}
                                  </pre>
                                </>
                              )}
                            </div>
                          </details>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${backgroundnuvens})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <Col className="p-0" style={{ zIndex: 2 }}>
                <div className="d-flex justify-content-center align-items-baseline">
                  <img
                    src={h12}
                    alt="h12"
                    style={{
                      width: '100%',
                      maxWidth: '600px',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Footer />
          </Container>
        )
      );
    }

    return children;
  }
}

ErrorBoundaryClass.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func,
};

ErrorBoundaryClass.defaultProps = {
  fallback: null
};

// Função para lidar com erros assíncronos
export const handleAsyncError = (error, instance) => {
  if (instance) {
    instance.setState({
      hasError: true,
      error,
      errorInfo: {
        componentStack: error.stack || 'No stack trace available'
      }
    });
  }
};

// Wrapper funcional para usar hooks do React Router
const ErrorBoundaryWrapper = forwardRef((props, ref) => <ErrorBoundaryClass {...props} ref={ref} />);

export { ErrorBoundaryClass };
export default ErrorBoundaryWrapper;
