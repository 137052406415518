/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef } from 'react';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Container,
} from 'reactstrap';

import { validateCPF } from 'validations-br';
import * as Yup from 'yup';

import logo2 from '../../assets/img/logocompleto.png';
import Footer from '../../components/Footer';
import Notification from '../../components/Notification';
import api from '../../services/api';

function Register() {
  const navigate = useNavigate();
  const notificationRef = useRef();
  const [validationErrors, setValidationErrors] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const handleSubmit = () => {
    (async () => {
      try {
        const data = {
          name,
          cpf,
          email,
          password,
          passwordConfirmation,
        };

        const schema = Yup.object().shape({
          name: Yup.string().required('Informe seu nome'),
          cpf: Yup.string()
            .test('is-valid-cpf', 'CPF inválido', (value) => validateCPF(value))
            .required('Informe um CPF'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email'),
          password: Yup.string().required('Informe uma senha'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas precisam ser iguais',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        await api.post('/profissionais', data);

        notificationRef.current.notify({
          message: 'Cadastro realizado com sucesso',
        });

        setTimeout(() => {
          navigate('/');
        }, 2000);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Precisa informar todos os campos para cadastrar-se',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message, typeError } = err.response.data;

          if (typeError === 'VALIDATION_ERROR') {
            setValidationErrors(err.response.data.validationErrors);
          }

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha tentar ao realizar cadastro',
          color: 'danger',
        });
      }
    })();
  };

  return (
    <Container fluid>
      <Notification ref={notificationRef} />
      <Row>
        <Col xs={3} className="bg-danger min-vh-100">
          <Row>
            <Col>
              <div className="d-flex justify-content-center">
                <img
                  src={logo2}
                  alt=""
                  style={{
                    width: '100%',
                    maxWidth: '200px',
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={9}>
          <Row className="justify-content-center p-sm-5">
            <Col xs="12" style={{ zIndex: 2 }}>
              <Row className="my-5">
                <Col>
                  <h3 className="text-center">Solicitar Acesso</h3>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <span className="text-justify">
                    Vamos precisar de algumas informações para liberar
                    o seu acesso ok?
                    Depois de preenchido é só aguardar a liberação.
                    Tudo bem?
                  </span>
                </Col>
              </Row>
              <Form className="mt-5">
                <FormGroup>
                  <Input
                    type="name"
                    placeholder="Nome Completo"
                    value={name}
                    invalid={!!validationErrors.name}
                    onChange={({ target: { value } }) => setName(value)}
                  />
                  <FormFeedback>{validationErrors.name}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <PatternFormat
                    customInput={Input}
                    format="###.###.###-##"
                    mask="_"
                    valueIsNumericString
                    placeholder="CPF"
                    value={cpf}
                    invalid={!!validationErrors.cpf}
                    onValueChange={({ value }) => setCpf(value)}
                  />
                  <FormFeedback>{validationErrors.cpf}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="email"
                    placeholder="E-mail para Login"
                    value={email}
                    invalid={!!validationErrors.email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <FormFeedback>{validationErrors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    invalid={!!validationErrors.password}
                    onChange={({ target: { value } }) => setPassword(value)}
                  />
                  <FormFeedback>{validationErrors.password}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Repetir Senha"
                    value={passwordConfirmation}
                    invalid={!!validationErrors.passwordConfirmation}
                    onChange={({ target: { value } }) => setPasswordConfirmation(value)}
                  />
                  <FormFeedback>{validationErrors.passwordConfirmation}</FormFeedback>
                </FormGroup>
                <Row>
                  <Col>
                    <Button color="secondary" onClick={handleSubmit} block>
                      Enviar
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color="link"
                      className="mt-4"
                      onClick={() => navigate('/login')}
                      block
                    >
                      Já tem conta? fazer login.
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
