/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useCallback, useContext, useLayoutEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Col,
  Row,
  Container,
  CardBody,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Alert,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';

import CustomMaskedInput from '../../components/CustomMaskedInput';
import Divider from '../../components/Divider';
import ImageFileInpup from '../../components/ImageFileInput';
import { AppContext } from '../../contexts/app';
import useApp from '../../hooks/useApp';
import useAuth from '../../hooks/useAuth';
import api from '../../services/api';
import { schema } from './schemaValidations';

const defaultValues = {
  name: '',
  cpf: '',
  email: '',
  passwordCurrent: '',
  password: '',
  passwordConfirmation: '',
};

function Profiles() {
  const [image, setImage] = useState('');
  const { setLoading, notificationRef } = useApp();
  const { setLoggedUser, loggedUser } = useAuth();
  const { control, setValue, handleSubmit, formState: { errors } } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { setHeaderTitle } = useContext(AppContext);

  useLayoutEffect(() => {
    setHeaderTitle('Meus Dados');
  }, [setHeaderTitle]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('/getEspecialistas');
        setValue('name', data.name);
        setValue('cpf', data.cpf);
        setValue('email', data.email);
        setImage(data.image ? data.image_url : '');
      } catch (error) {
        console.log('🚀 ~ file: index.js:54 ~ catch (error):', error);
      }
    })();
  }, []);

  const handleClearCacheAndReload = useCallback(() => {
    (async () => {
      try {
        // Clear browser caches
        if ('caches' in window) {
          try {
            const keys = await caches.keys();
            await Promise.all(
              keys.map((key) => {
                if (key.includes('nic-app')) {
                  return caches.delete(key);
                }
                return Promise.resolve();
              })
            );
            console.log('Cache cleared successfully');
          } catch (error) {
            console.error('Error clearing cache:', error);
          }
        }

        // Reload the page
        window.location.reload();
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    })();
  }, []);

  const onSubmit = useCallback((formValues) => {
    (async () => {
      try {
        console.log('🚀 ~ file: Profile index.js ~ onSubmit ~ formValues:', formValues);
        const { name, cpf, email, password, passwordCurrent } = formValues;

        setLoading(true);
        const formData = new FormData();

        formData.append('name', name);
        formData.append('email', email);
        formData.append('cpf', cpf);

        if (password) {
          formData.append('password', password);
          formData.append('passwordCurrent', passwordCurrent);
        }

        if (formValues.image instanceof File) {
          formData.append('image', formValues.image);
        }

        // validation passed
        const { data } = await api.put('/profissionalProfiles', formData);
        console.log('🚀 ~ file: index.js:83 ~ data:', data);

        setLoggedUser(data);
        notificationRef.current.notify({
          message: 'Cadastro atualizado com sucesso',
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log('🚀 ~ file: Profile index.js ~ error:', err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha tentar ao atualizar cadastro',
          color: 'danger',
        });
      }
    })();
  }, []);

  return (
    <Container fluid>
      <Form className="pb-5" onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-4">
          <Col>
            <Card className="shadow" style={{ backgroundColor: 'transparent' }}>
              <CardBody tag="div" className="d-flex flex-column justify-content-center align-items-center">
                <Controller
                  name="image"
                  control={control}
                  render={({ field: { onChange, name } }) => (
                    <ImageFileInpup
                      shape="avatar"
                      previewUrl={image}
                      name={name}
                      onChange={onChange}
                    />
                  )}
                />
                <Alert color="warning">Para alterar ou incluir uma imagem, será necessário autorizar o acesso a sua câmera e/ou sua biblioteca de fotos.</Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormGroup>
              <Label for="name">name Completo</Label>
              <Input
                id="name"
                type="text"
                placeholder="Nome Completo"
                name={name}
                value={value}
                onChange={onChange}
                invalid={!!errors?.name?.message}
              />
              <FormFeedback>{errors?.name?.message}</FormFeedback>
            </FormGroup>
          )}
        />
        <Controller
          name="cpf"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <CustomMaskedInput
              id="cpf"
              label="CPF"
              placeholder="Informe um CPF"
              format="###.###.###-##"
              name={name}
              value={value}
              onValueChange={({ value: v }) => onChange(v)}
              invalidMessage={errors?.cpf?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormGroup>
              <Label for="email">Email para Login/Contato</Label>
              <Input
                id="email"
                type="email"
                placeholder="Email"
                name={name}
                value={value}
                onChange={onChange}
                invalid={!!errors?.email?.message}
              />
              <FormFeedback>{errors?.email?.message}</FormFeedback>
            </FormGroup>
          )}
        />
        <Row>
          <Col tag="div" className="d-flex justify-content-around align-items-center">
            <Divider width="30%" />
            {' '}
            Alterar Senha
            {' '}
            <Divider width="30%" />
          </Col>
        </Row>
        <Controller
          name="passwordCurrent"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormGroup>
              <Label for="passwordCurrent">Senha Atual</Label>
              <Input
                id="passwordCurrent"
                type="password"
                placeholder="Senha Atual"
                name={name}
                value={value}
                onChange={onChange}
                invalid={!!errors?.passwordCurrent?.message}
              />
              <FormFeedback>{errors?.passwordCurrent?.message}</FormFeedback>
            </FormGroup>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormGroup>
              <Label for="password">Nova Senha</Label>
              <Input
                id="password"
                type="password"
                placeholder="Nova Senha"
                name={name}
                value={value}
                onChange={onChange}
                invalid={!!errors?.password?.message}
              />
              <FormFeedback>{errors?.password?.message}</FormFeedback>
            </FormGroup>
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <FormGroup>
              <Label for="passwordConfirmation">Confirme a Nova Senha</Label>
              <Input
                id="passwordConfirmation"
                type="password"
                placeholder="Confirme a Nova Senha"
                name={name}
                value={value}
                onChange={onChange}
                invalid={!!errors?.passwordConfirmation?.message}
              />
              <FormFeedback>{errors?.passwordConfirmation?.message}</FormFeedback>
            </FormGroup>
          )}
        />
        <Row>
          <Col>
            <Button type="submit" className="mb-5" block>
              Salvar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="button" className="mt-5" color="primary" block onClick={handleClearCacheAndReload}>
              <i className="fas fa-sync-alt mr-2" />
              {' '}
              Limpar Cache e Recarregar
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Button color="danger" tag="a" href={`${process.env.REACT_APP_SOLICITATION_DELETE_ACOUNT_URL}${loggedUser?.email ? `/${encodeURI(loggedUser.email)}` : ''}`} target="_blank" rel="noreferrer noopenner" block>
              Solicitar exclusão de conta
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default Profiles;
