/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  Button,
  ModalBody,
  ModalFooter,
  Form,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Badge,
  Table,
  Input,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { differenceInSeconds, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import useApp from '../../hooks/useApp';
import useSecondsToDhms from '../../hooks/useSecondsToDhms';
import api from '../../services/api';
import CustomModalMotivoCancelamento from '../CustomModalMotivoCancelamento';
import TimePickerMaskedInput from '../TimePickerMaskedInput';
import { schema } from './validationSchema';

function AgendamentoCard({
  agendamento: agendamentoData,
  onAgendamentoCancel,
  isWarning = false,
  showDate = false,
}) {
  if (!agendamentoData) {
    return null;
  }

  const navigate = useNavigate();
  const modalMotivoCancelamentoRef = useRef();
  const { setLoading, notificationRef } = useApp();
  const [agendamento, setAgendamento] = useState(agendamentoData);
  const [showFinalizarAtendimentoModal, setShowFinalizarAtendimentoModal] = useState(false);
  const [dataAtendimento, setDataAtendimento] = useState();
  const { convertSecondsToDhms } = useSecondsToDhms();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  const {
    _id,
    status,
    cliente,
    salaAtendimento,
    especialidade,
    dataHoraInicio,
    dataHoraTermino,
    dataHoraInicioAtendimento,
    dataHoraTerminoAtendimento,
    dataCancelamento,
    motivoCancelamento,
    adminFaturamento,
    canceladoByAdmin,
    canceladoByProfissional,
    canceladoByCliente,
    financeiros = [],
  } = agendamento;

  if (!cliente?.nome) {
    return null;
  }

  const handleEdit = useCallback(() => {
    navigate(`/agendamento/${agendamento._id}`);
  }, [navigate, agendamento]);

  const onCancel = useCallback((data) => {
    setAgendamento(data);

    if (typeof onAgendamentoCancel === 'function') {
      onAgendamentoCancel(data);
    }

    notificationRef.current.notify({
      message: 'Atendimento cancelado com sucesso',
    });
  }, [onAgendamentoCancel]);

  const handleCancel = useCallback(() => {
    modalMotivoCancelamentoRef.current.show({
      _id,
      onSave: (data) => onCancel(data),
    });
  }, [_id]);

  const handleFinish = useCallback(() => {
    setDataAtendimento(new Date(dataHoraInicio));
    setShowFinalizarAtendimentoModal(true);
    setValue('dataHoraInicioAtendimento', new Date(dataHoraInicio));
    setValue('dataHoraTerminoAtendimento', new Date(dataHoraTermino));
  }, [dataHoraInicio, dataHoraTermino]);

  const onSubmitFinish = useCallback((formValues) => {
    console.log(formValues);
    (async () => {
      try {
        setLoading(true);
        const data = {
          cliente,
          especialidade: especialidade._id,
          dataHoraInicioAtendimento: formValues.dataHoraInicioAtendimento,
          dataHoraTerminoAtendimento: formValues.dataHoraTerminoAtendimento,
          observacoes: formValues.observacoes,
        };

        console.log(data);
        await api.put(`/profissionalAtendimentos/${_id}`, data);
        setLoading(false);
        notificationRef.current.notify({
          message: 'Atendimento finalizado com sucesso',
        });

        setAgendamento((prevState) => ({ ...prevState, status: 'concluido' }));
        setValue('dataHoraInicioAtendimento', null);
        setValue('dataHoraTerminoAtendimento', null);
        setShowFinalizarAtendimentoModal(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao Finalizar Atendimento',
          color: 'danger',
        });
      }
    })();
  }, [_id, cliente, especialidade]);

  const renderCanceladoBy = useCallback(() => {
    if (canceladoByAdmin?.name) {
      return (
        <Badge color="danger" className="text-wrap">
          <span>Cancelado</span>
          {` pelo Admin ${canceladoByAdmin.name} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}
        </Badge>
      );
    }

    if (canceladoByProfissional?.name) {
      return (
        <Badge color="danger" className="text-wrap">
          <span>Cancelado</span>
          {` pelo Profissional ${canceladoByProfissional.name} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}
        </Badge>
      );
    }

    if (canceladoByCliente?.nome) {
      return (
        <Badge color="danger" className="text-wrap">
          <span>Cancelado</span>
          {` pelo Cliente ${canceladoByCliente.nome} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}
        </Badge>
      );
    }

    return (
      <Badge>
        Não Identificado
      </Badge>
    );
  }, [canceladoByAdmin, canceladoByProfissional, canceladoByCliente]);

  return (
    <>
      <CustomModalMotivoCancelamento ref={modalMotivoCancelamentoRef} />
      <Card className={classNames('shadow mb-2 mr-0 mr-sm-2', { 'border border-warning': isWarning })}>
        <CardHeader>
          <strong>{`Paciente: ${cliente?.nome}`}</strong>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              {`Sala de Atendimento: ${salaAtendimento?.nome}`}
            </Col>
          </Row>
          <Row>
            <Col>
              {`Tipo de Atendimento: ${especialidade?.nome}`}
            </Col>
          </Row>
          {adminFaturamento?.name ? (
            <Row>
              <Col>
                {`Responsável Financeiro: ${adminFaturamento?.name}`}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                Sem Responsável Financeiro
              </Col>
            </Row>
          )}
          {showDate && (
            <Row>
              <Col>
                <Badge color={isWarning ? 'warning' : 'info'} className="w-100">
                  {`Data do Atendimento: ${format(new Date(dataHoraInicio), 'dd/MM/yyyy', { locale: ptBR })}`}
                </Badge>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Início</th>
                    <th>Término</th>
                    <th>Duração</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <UncontrolledTooltip target="agendamento">
                        Agendamento
                      </UncontrolledTooltip>
                      <i id="agendamento" className="fa-solid fa-calendar-day text-info" />
                    </td>
                    <td>{`${format(new Date(dataHoraInicio), 'HH:mm', { locale: ptBR })}`}</td>
                    <td>{`${format(new Date(dataHoraTermino), 'HH:mm', { locale: ptBR })}`}</td>
                    <td>{`${convertSecondsToDhms(differenceInSeconds(new Date(dataHoraTermino), new Date(dataHoraInicio)))}`}</td>
                  </tr>
                  {status === 'cancelado' && dataCancelamento && (
                    <tr>
                      <td>
                        <UncontrolledTooltip target="cancelamento">
                          Cancelamento
                        </UncontrolledTooltip>
                        <i id="cancelamento" className="fa-solid fa-calendar-xmark text-danger" />
                      </td>
                      <td colSpan={3} style={{ width: '100%', maxWidth: '150px' }}>
                        {renderCanceladoBy()}
                      </td>
                    </tr>
                  )}
                  {status === 'cancelado' && dataCancelamento && motivoCancelamento && (
                    <tr>
                      <td>
                        <UncontrolledTooltip target="cancelamento-motivo">
                          Motivo do cancelamento
                        </UncontrolledTooltip>
                        <i id="cancelamento-motivo" className="fa-solid fa-calendar-week text-danger" />
                      </td>
                      <td colSpan={3} style={{ width: '100%', maxWidth: '150px' }}>
                        <span className="text-wrap">
                          {motivoCancelamento}
                        </span>
                      </td>
                    </tr>
                  )}
                  {status === 'concluido' && dataHoraInicioAtendimento && dataHoraTerminoAtendimento && (
                    <tr>
                      <td>
                        <UncontrolledTooltip target="atendimento">
                          Atendimento
                        </UncontrolledTooltip>
                        <i id="atendimento" className="fa-regular fa-calendar-check text-success" />
                      </td>
                      <td>{`${format(new Date(dataHoraInicioAtendimento), 'HH:mm', { locale: ptBR })}`}</td>
                      <td>{`${format(new Date(dataHoraTerminoAtendimento), 'HH:mm', { locale: ptBR })}`}</td>
                      <td>{`${convertSecondsToDhms(differenceInSeconds(new Date(dataHoraTerminoAtendimento), new Date(dataHoraInicioAtendimento)))}`}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="d-flex justify-content-end" style={{ height: '40px' }}>
            {agendamento.status === 'aberto' ? (
              <>
                <UncontrolledTooltip target="#agendamento-edit">Editar</UncontrolledTooltip>
                <Button id="agendamento-edit" className="mr-2" onClick={handleEdit}>
                  <i className="fa-solid fa-pen-to-square text-info" />
                </Button>
                <UncontrolledTooltip target="#agendamento-cancel">Cancelar</UncontrolledTooltip>
                <Button id="agendamento-cancel" className="mr-2" onClick={handleCancel}>
                  <i className="fa-solid fa-calendar-xmark text-danger" />
                </Button>
                <UncontrolledTooltip target="#agendamento-finish">Finalizar</UncontrolledTooltip>
                <Button id="agendamento-finish" onClick={handleFinish}>
                  <i className="fa-solid fa-circle-check text-success" />
                </Button>
              </>
            ) : (
              agendamento.status === 'cancelado' ? (
                <Badge color="danger">Agendamento Cancelado</Badge>
              ) : (
                <>
                  <Badge color="success">Atendimento Concluido</Badge>
                  {financeiros.every((financeiro) => financeiro.status === 'aberto') && (
                    <>
                      <Button id="agendamento-finish" className="ml-2" onClick={handleFinish}>
                        <i className="fa-solid fa-circle-check text-success" />
                      </Button>
                      <Button id="agendamento-edit" className="ml-2" onClick={handleEdit}>
                        <i className="fa-solid fa-pen-to-square text-info" />
                      </Button>
                    </>
                  )}
                </>
              )
            )}
          </div>
        </CardFooter>
      </Card>
      <Modal
        isOpen={showFinalizarAtendimentoModal}
        toggle={() => setShowFinalizarAtendimentoModal(!showFinalizarAtendimentoModal)}
        centered
      >
        <Form onSubmit={handleSubmit(onSubmitFinish)}>
          <ModalHeader>
            <strong>Finalizar Atendimento</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowFinalizarAtendimentoModal(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Controller
              name="dataHoraInicioAtendimento"
              control={control}
              render={({ field }) => (
                <TimePickerMaskedInput
                  label="Hora de início do atendimento"
                  date={dataAtendimento}
                  invalidMessage={errors?.dataHoraInicioAtendimento?.message}
                  onChange={(e) => field.onChange(e)}
                  selected={field.value}
                  // includeTimes={includeTimes}
                />
              )}
            />
            <Controller
              name="dataHoraTerminoAtendimento"
              control={control}
              render={({ field }) => (
                <TimePickerMaskedInput
                  label="Hora de término do atendimento"
                  date={dataAtendimento}
                  invalidMessage={errors?.dataHoraTerminoAtendimento?.message}
                  onChange={(e) => field.onChange(e)}
                  selected={field.value}
                  // includeTimes={includeTimes}
                />
              )}
            />
            <Controller
              name="observacoes"
              control={control}
              render={({ field }) => (
                <Input
                  type="textarea"
                  placeholder="Observações"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit">Finalizar</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default AgendamentoCard;
