/* eslint-disable no-plusplus */
import { useCallback } from 'react';

import { setHours, setMinutes } from 'date-fns';

function useGenerateIntervalHours() {
  const generateIntervalHours = useCallback((date, startHour, endHour, startMinute, endMinute) => {
    if (!date || startHour === undefined || endHour === undefined) return [];

    const times = [];

    for (let hour = startHour; hour <= endHour; hour++) {
      const minStart = hour === startHour ? startMinute : 0;
      const minEnd = hour === endHour ? endMinute : 55;

      for (let minute = minStart; minute <= minEnd; minute += 5) {
        times.push(setHours(setMinutes(date, minute), hour));
      }
    }

    return times;
  }, []);

  return {
    generateIntervalHours,
  };
}

export default useGenerateIntervalHours;
