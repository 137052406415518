import { useCallback, useState } from 'react';

import useApp from '../../hooks/useApp';
import api from '../../services/api';

function useFaturasDetails() {
  const { setLoading: setLoadingApp } = useApp();
  const [faturasDetails, setFaturasDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [situation, setSituation] = useState('');

  const load = useCallback((params) => {
    (async () => {
      try {
        setLoading(true);
        setPage(params.page || page);
        const query = new URLSearchParams({
          page: params.page,
        });
        const { data = { docs: [], pagination: { hasNextPage: false } } } = await api.get(`/profissionalFaturasMensal/year/${params.year || year}/month/${params.month || month}/situation/${params.situation || situation}?${query.toString()}`);
        setFaturasDetails((prev = []) => (!params?.page ? data.docs : [...prev, ...data.docs]));
        setHasNextPage(data.pagination.hasNextPage);
        setLoading(false);
        setYear(params.year || year);
        setMonth(params.month || month);
        setSituation(params.situation || situation);
      } catch (error) {
        console.log('🚀 ~ error:', error);
      }
    })();
  }, [page, year, month, situation]);

  const download = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        setLoadingApp({
          loading: true,
          message: 'Aguarde... Gerando PDF.',
        });
        const res = await api.get(`/profissionalFaturasMensal/year/${year}/month/${month}/situation/${situation}/downloadPDF`, { responseType: 'blob' });

        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
        setLoadingApp(false);
      } catch (error) {
        setLoading(false);
        setLoadingApp(false);
        console.error(error);
      }
    })();
  }, [year, month, situation]);

  return {
    faturasDetails,
    loading,
    hasNextPage,
    page,
    load,
    download,
  };
}

export default useFaturasDetails;
