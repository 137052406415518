import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';

import classNames from 'classnames';

import Topbar from './TopBar';

import './styles.css';

function Content({ sidebarIsShow, sidebarIsOpen, toggleSidebar }) {
  return (
    <Container
      fluid
      className={classNames('content', { 'is-open': sidebarIsOpen, 'sidebar-noshow': !sidebarIsShow })}
    >
      <Topbar sidebarIsShow={sidebarIsShow} toggleSidebar={toggleSidebar} />
      <section>
        <Outlet />
      </section>
    </Container>
  );
}

export default Content;
