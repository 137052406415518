/* eslint-disable consistent-return */
import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import Notification from '../components/Notification';
import useApp from '../hooks/useApp';
import useAuth from '../hooks/useAuth';
import useSocket from '../hooks/useSocket';
import api from '../services/api';

export const NotificationsContext = createContext();

function NotificationProvider({ children }) {
  const notificationRef = useRef();
  const { loggedUser } = useAuth();
  const { socket } = useSocket();
  const { setLoading: setLoadingApp } = useApp();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [filters, setFilters] = useState({
    tipo: '',
    status: '',
    dataDe: '',
    dataAte: '',
  });

  useEffect(() => {
    if (!socket) return;

    socket?.on("notification:new", (newMessage) => {
      console.log("Mensagem do Servidor:", newMessage);
      setUnreadNotifications((prev) => prev + 1);
      notificationRef.current.notify({
        message: newMessage.message,
        color: "info",
      });
    });

    return () => {
      socket?.off("notification:new");
    };
  }, [socket]);

  useEffect(() => {
    try {
      if (loggedUser?.id) {
        // setLoadingApp(true);
        setLoading(true);
        const fetchNotifications = async () => {
          const { data } = await api.get('/notifications');
          setNotifications(data?.docs || []);
          setUnreadNotifications(data?.unreadNotifications || 0);
          // setLoadingApp(false);
          setLoading(false);
        };
        fetchNotifications();
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        const { message } = error.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível carregar as notificações',
        color: 'danger',
      });
    }
  }, [loggedUser]);

  const onDelete = async (n) => {
    try {
      setLoadingApp(true);
      setLoading(true);
      await api.delete(`/notifications/${n._id}`);
      setNotifications(notifications.filter((_n) => _n._id !== n._id));
      notificationRef.current.notify({
        message: 'Notificação deletada com sucesso',
        color: 'success',
      });
    } catch (error) {
      if (error && error.response && error.response.data) {
        const { message } = error.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });
      }
    } finally {
      setLoadingApp(false);
      setLoading(false);
    }
  };

  // /notifications/:id/status/read
  const onMarkAsRead = async (n) => {
    try {
      setLoadingApp(true);
      setLoading(true);
      await api.put(`/notifications/${n._id}/status/read`);
      setNotifications(notifications.map((_n) => (_n._id === n._id ? { ..._n, status: 'lido' } : _n)));
      setUnreadNotifications((prev) => prev - 1);
      notificationRef.current.notify({
        message: 'Notificações atualizadas com sucesso',
        color: 'success',
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    } finally {
      setLoadingApp(false);
      setLoading(false);
    }
  };

  // /notifications/:id/accepted
  const onAccept = async (n) => {
    try {
      setLoadingApp(true);
      setLoading(true);
      const { data } = await api.put(`/notifications/${n._id}/accepted`);
      setNotifications(notifications.map((_n) => (_n._id === n._id ? { ..._n, status: 'lido', accepted: true, acceptedAt: data.acceptedAt } : _n)));
      setUnreadNotifications((prev) => prev - 1);
      notificationRef.current.notify({
        message: 'Aceito com sucesso',
        color: 'success',
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    } finally {
      setLoadingApp(false);
      setLoading(false);
    }
  };

  // /notifications/:id/rejected
  const onReject = async (n) => {
    try {
      setLoadingApp(true);
      setLoading(true);
      const { data } = await api.put(`/notifications/${n._id}/rejected`);
      setNotifications(notifications.map((_n) => (_n._id === n._id ? { ..._n, status: 'lido', rejected: true, rejectedAt: data.rejectedAt } : _n)));
      setUnreadNotifications((prev) => prev - 1);
      notificationRef.current.notify({
        message: 'Rejeitado com sucesso',
        color: 'success',
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    } finally {
      setLoadingApp(false);
      setLoading(false);
    }
  };

  // /notifications/:id/archived
  const onArchive = async (n) => {
    try {
      setLoadingApp(true);
      setLoading(true);
      const { data } = await api.put(`/notifications/${n._id}/archived`);
      setNotifications(notifications.map((_n) => (_n._id === n._id ? { ..._n, status: 'arquivado', archivedAt: data.archivedAt } : _n)));
      setUnreadNotifications((prev) => prev - 1);
      notificationRef.current.notify({
        message: 'Notificação arquivada com sucesso',
        color: 'success',
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    } finally {
      setLoadingApp(false);
      setLoading(false);
    }
  };

  // /notifications/:id/unArchived
  const onRestore = async (n) => {
    try {
      setLoadingApp(true);
      setLoading(true);
      await api.put(`/notifications/${n._id}/unArchived`);
      setNotifications(notifications.map((_n) => (_n._id === n._id ? { ..._n, status: 'lido', archivedAt: null } : _n)));
      setUnreadNotifications((prev) => prev - 1);
      notificationRef.current.notify({
        message: 'Notificação restaurada com sucesso',
        color: 'success',
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    } finally {
      setLoadingApp(false);
      setLoading(false);
    }
  };

  const onFilter = async (_filters) => {
    try {
      setLoadingApp(true);
      setLoading(true);
      const query = new URLSearchParams();
      query.append('tipo', _filters?.tipo || '');
      query.append('status', _filters?.status || '');
      query.append('dataDe', _filters?.dataDe || '');
      query.append('dataAte', _filters?.dataAte || '');
      const response = await api.get(`/notifications?${query.toString()}`);
      setNotifications(response?.data?.docs || []);
      notificationRef.current.notify({
        message: 'Notificações filtradas com sucesso',
        color: 'success',
      });
    } catch (error) {
      console.error(error);
      notificationRef.current.notify({
        message: 'Erro ao filtrar notificações',
        color: 'danger',
      });
    } finally {
      setLoadingApp(false);
      setLoading(false);
    }
  };

  const onClearFilters = () => {
    setFilters({});
    onFilter({});
  };

  const value = useMemo(
    () => ({
      loading,
      notifications,
      unreadNotifications,
      onDelete,
      onMarkAsRead,
      onAccept,
      onReject,
      onArchive,
      onRestore,
      onFilter,
      filters,
      setFilters,
      onClearFilters,
    }),
    [
      loading,
      notifications,
      unreadNotifications,
      onDelete,
      onMarkAsRead,
      onAccept,
      onReject,
      onArchive,
      onRestore,
      onFilter,
      filters,
      setFilters,
      onClearFilters,
    ],
  );

  return (
    <NotificationsContext.Provider value={value}>
      <Notification ref={notificationRef} />
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationProvider;
