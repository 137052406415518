import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

function Confirm(props, ref) {
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState({
    title: 'Mensagem',
    iconColor: 'primary',
    confirmColor: 'success',
    confirmText: 'Ok',
    hideConfirm: false,
    hideCancel: false,
    cancelText: 'Cancelar',
    cancelColor: 'danger',
    option1Text: null,
    option1Color: 'primary',
    option2Text: null,
    option2Color: 'secondary',
  });

  useImperativeHandle(ref, () => ({
    show: (data) => {
      // console.log(data);
      setModal({
        ...modal,
        ...data,
        hideConfirm: data?.confirmText ? false : data?.hideConfirm || false,
        hideCancel: data?.cancelText ? false : data?.hideCancel || false,
        option1Text: data?.option1Text || null,
        option2Text: data?.option2Text || null,
      });
      setShow(true);
    },
    close: () => setShow(false),
  }));

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow(false)}
      centered
    >
      <ModalHeader>
        <strong>{modal.title}</strong>
        <Button
          className="close position-absolute top-4 right-4 py-1 px-2"
          onClick={() => setShow(false)}
        >
          <i className="fas fa-times" />
        </Button>
      </ModalHeader>
      <ModalBody>{modal.message}</ModalBody>
      <ModalFooter>
        {!modal.hideConfirm && (
          <Button color={modal.confirmColor} onClick={modal.onConfirm}>
            {modal.confirmText}
          </Button>
        )}
        {!modal.hideCancel && (
          <Button
            color={modal.cancelColor}
            onClick={modal.onCancel || (() => setShow(false))}
          >
            {modal.cancelText}
          </Button>
        )}
        {modal.option1Text && (
          <Button color={modal.option1Color} onClick={modal.onOption1}>
            {modal.option1Text}
          </Button>
        )}
        {modal.option2Text && (
          <Button color={modal.option2Color} onClick={modal.onOption2}>
            {modal.option2Text}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default forwardRef(Confirm);
