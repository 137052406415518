import { useEffect, useCallback, useState } from 'react';

import api from '../../services/api';

function useFaturas() {
  const [faturas, setFaturas] = useState([]);
  const [loading, setLoading] = useState(true);

  const load = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get('/profissionalFaturasMensal');
        setFaturas(response.data);
        setLoading(false);
      } catch (error) {
        console.log('🚀 ~ error:', error);
      }
    })();
  }, []);

  useEffect(() => {
    load();
  }, []);

  return {
    faturas,
    loading,
  };
}

export default useFaturas;
