/* eslint-disable no-unused-vars */
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

async function registerValidSW(swUrl) {
  try {
    if (!('serviceWorker' in navigator)) {
      console.warn('Service Worker não é suportado neste navegador');
      return;
    }

    const registration = await navigator.serviceWorker.register(swUrl);

    // Initialize Firebase messaging with the registration
    if (window.firebase?.messaging) {
      try {
        const messaging = window.firebase.messaging();
        await messaging.useServiceWorker(registration);
      } catch (error) {
        console.warn('Erro ao configurar Firebase Messaging:', error);
      }
    }

    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (!installingWorker) {
        return;
      }

      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            console.log('Novo conteúdo está disponível; por favor atualize.');
          } else {
            console.log('Conteúdo está em cache para uso offline.');
          }
        }
      };
    };
  } catch (error) {
    console.error('Erro durante o registro do service worker:', error);
  }
}

async function checkValidServiceWorker(swUrl) {
  try {
    // Verifica se tem conexão com a internet
    const response = await fetch(swUrl);

    // Garante que o service worker existe e que estamos recebendo um arquivo JS
    const contentType = response.headers.get('content-type');
    if (response.status === 404 || (contentType && contentType.indexOf('javascript') === -1)) {
      // Service worker não encontrado. Provavelmente outro app.
      try {
        const registration = await navigator.serviceWorker.ready;
        await registration.unregister();
        window.location.reload();
      } catch (error) {
        console.error('Erro ao desregistrar service worker:', error);
      }
    } else {
      // Service worker encontrado. Procede normalmente.
      await registerValidSW(swUrl);
    }
  } catch (error) {
    alert('Sem conexão com internet. App está rodando em modo offline.');
    console.log('Sem conexão com internet. App está rodando em modo offline.');
  }
}

export function register() {
  if ('serviceWorker' in navigator) {
    try {
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

      if (publicUrl.origin !== window.location.origin) {
        // Service worker não funcionará se PUBLIC_URL estiver em uma origem diferente
        console.warn('Service Worker ignorado devido a origem diferente');
        return;
      }

      window.addEventListener('load', async () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        if (isLocalhost) {
          console.log('Este app esta rodando em localhost');
          await checkValidServiceWorker(swUrl);
        } else {
          await checkValidServiceWorker(swUrl);
        }
      });
    } catch (error) {
      console.error('Erro ao configurar service worker:', error);
    }
  }
}

export async function unregister() {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      await registration.unregister();
    } catch (error) {
      console.error('Erro ao desregistrar service worker:', error);
    }
  }
}
