import React, { useState, useRef, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  InputGroupText,
  InputGroup,
  Container,
} from 'reactstrap';

import * as Yup from 'yup';

import Divider from '../../components/Divider';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Notification from '../../components/Notification';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function ForgetPassword() {
  const { setLoading } = useContext(AppContext);
  const notificationRef = useRef();
  const [validationErrors, setValidationErrors] = useState({});
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    (async () => {
      try {
        setLoading(true);
        const data = {
          email,
        };

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        await api.post('/recoverPassword', data);
        notificationRef.current.notify({
          message: 'Email enviado com sucesso',
        });

        setSuccess(true);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setSuccess(false);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Falha ao tentar recuperar a senha',
          color: 'danger',
        });
      }
    })();
  };

  return (
    <Container fluid>
      <Notification ref={notificationRef} />
      {success ? (
        <>
          <Header />
          <Row className="mb-5" />
          <div className="p-5">
            <Row className="justify-content-center mb-3">
              <h5>Email de solicitação de alteração de senha enviado com sucesso</h5>
            </Row>
            <Row className="justify-content-center">
              <Col xs="12" sm="10" md="8" lg="6">
                <Card className="p-4" color="secondary">
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <i
                      className="fas fa-check-circle text-success"
                      style={{
                        fontSize: '180pt',
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="text-white text-center mb-5">
              <Col>
                <Divider />
                <p>
                  Você receberá um e-mail para alterar sua senha.
                  {' '}
                  <small>
                    (Caso o e-mail não se encontre na caixa de entrada, por
                    favor verifique seu spam)
                  </small>
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Button color="warning" onClick={handleSubmit}>
                Reenviar email de recuperação de senha
              </Button>
            </Row>
          </div>
        </>
      ) : (
        <>
          <Header />
          <Row className="mb-5" />
          <Row className="text-white text-center mb-2">
            <Col>
              <h6>Informe seu email cadastrado para recuperar a senha</h6>
              <Divider />
            </Col>
          </Row>
          <Row className="justify-content-center p-3">
            <Col xs="12" sm="8" md="6" lg="4">
              <Card className="p-0 p-md-3" color="secondary">
                <CardBody>
                  <Form>
                    <FormGroup>
                      <InputGroup
                        className={`input-group-merge ${
                          validationErrors.email ? 'invalid' : ''
                        }`}
                      >
                        <Input
                          type="email"
                          placeholder="Email"
                          value={email}
                          invalid={!!validationErrors.email}
                          onChange={({ target: { value } }) => setEmail(value)}
                        />
                        <InputGroupText>
                          <i className="fas fa-user" />
                        </InputGroupText>
                        <FormFeedback>{validationErrors.email}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                    <Row>
                      <Col>
                        <Button color="warning" onClick={handleSubmit} block>
                          Recuperar Senha
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <Footer />
    </Container>
  );
}

export default ForgetPassword;
