/* eslint-disable react/react-in-jsx-scope */
import Icon from './icon';

const navigation = [
  {
    showHomePage: false,
    to: '/perfil',
    name: 'Meus Dados',
    description: 'Atualizar minhas informações',
    icon: ({ fontSize, color, ...rest }) => <Icon name="fa-solid fa-user" color={color} fontSize={fontSize} {...rest} />,
  },
  {
    to: '/agenda',
    name: 'Agenda',
    description: 'Confira sua agenda',
    icon: ({ fontSize, color, ...rest }) => <Icon name="fa-solid fa-calendar-days" color={color} fontSize={fontSize} {...rest} />,
  },
  {
    to: '/faturas',
    name: 'Faturas',
    description: 'Consulte aqui suas faturas',
    icon: ({ fontSize, color, ...rest }) => <Icon name="fa-solid fa-file-invoice-dollar" color={color} fontSize={fontSize} {...rest} />,
  },
  {
    to: '/cores-config',
    name: 'Configurações de Cores',
    description: 'Configure as cores dos tipos de atendimento',
    icon: ({ fontSize, color, ...rest }) => <Icon name="fa-solid fa-palette" color={color} fontSize={fontSize} {...rest} />,
  },
  {
    to: '/notificacoes',
    name: 'Notificações',
    description: 'Consulte aqui suas notificações',
    icon: ({ fontSize, color, ...rest }) => <Icon name="fa-solid fa-bell" color={color} fontSize={fontSize} {...rest} />,
  },
  /* {
    to: '/reembolsos',
    name: 'Reembolsos',
    description: 'Consulte aqui seus reembolsos',
    icon: ({ fontSize, color, ...rest }) => <Icon name="fa-solid fa-money-bill-transfer" color={color} fontSize={fontSize} {...rest} />,
  }, */
];

export default navigation;
